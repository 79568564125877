import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown , faArrowUp} from '@fortawesome/free-solid-svg-icons'

export default function SuperTopRightProps(props) {
  return (
    <div>
        <div className='box-3 d-flex flex-column justify-content-evenly'>
            <div className='body-0 tx-color-cyan font-bold text-center'>
              <p className='m-0'>{props.amount}</p>              
            </div>
            <div className='body-4 font-regular text-center'>
              <p className='m-0'>{props.title}</p>
            </div>
            <div className='text-center'>
              <div className='d-flex gap-2 mt-1'>
                <div>
                  <p className='body-4 m-0'>{props.amount}</p>
                </div>
                {" "}

              { props.isUp ? (<div style={{display:'inline-block' , color:'#61E294'}}>
                <FontAwesomeIcon icon={faArrowUp}></FontAwesomeIcon>
              </div> ) : ( <div style={{display:'inline-block' , color:'#F500FF'}}>
                <FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon>
              </div>)

              }
              </div>
              
            </div>
        </div>
    </div>
  )
}
