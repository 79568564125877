import React  from 'react'


import { Row , Col } from 'react-bootstrap'

//import SuperTopRightProps from './SuperComponents/SuperTopRightProps'
import SuperTopRightProps from './SuperTopRightProps'



export default function SuperCenterTopRight() {



  return (
    <div >
        <div className='' >
            <p className='h2 font-bold'>Vacancies Statistics</p>
        </div>
        <div className='d-flex gap-3'>

            <div className=''>
                <SuperTopRightProps amount="325" title="New Users" isUp={false}></SuperTopRightProps>
            </div>

            <div className=''>
                <SuperTopRightProps amount="400" title="Total Users" isUp={true}></SuperTopRightProps>
            </div>

            <div className=''>
                <SuperTopRightProps amount="13" title="Active Users" isUp={false}></SuperTopRightProps>
            </div>

            <div className=''>
                <SuperTopRightProps amount="25" title="Inactive Users" isUp={true}></SuperTopRightProps>
            </div>

 

        </div>
       
    </div>
  )
}
