import React from 'react'

import { Container , Row , Image} from 'react-bootstrap'

//import { MdOutlinePeople } from "react-icons/md";

//import { IoPeople } from "react-icons/io5";



//import { AiOutlineFolderOpen , AiFillBell, AiFillFolderOpen } from "react-icons/ai";


//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faFolderOpen , faCheckToSlot , faMessage , faBell , faCircleUser , faCircleDollarToSlot , faGear } from '@fortawesome/free-solid-svg-icons'


//import UserMenu from './Left2Menu/UserMenu';
//import CompaniesMenu from './Left2Menu/CompaniesMenu';
//import InvoicesMenu from '../DashAdmin/Left2Menu/InvoicesMenu'
//import MessagesMenu from '../DashAdmin/Left2Menu/MessagesMenu';

//import CategoriesMenu from '../DashAdmin/Left2Menu/CategoriesMenu'

//import VacancyMenu from './Plus/VacancyMenu';

import { Tooltip } from '@mui/material';

import CorpProyects from './CorpProyects';
import CorpCandidates from './CorpCandidates';
import CorpTeam from './CorpTeam'
import CorpMessages from './CorpMessages';
import CorpInvoices from './CorpInvoices';
import CorpHunters from './CorpHunters';


export default function Left2( {centerTable}) {

  function centerTable(val)
  {
    console.log( "CenterTable = " + val ) ;
  }

  return (




    <Container className='p-0 m-0' style={{width:'70px'}} >
    <div className="d-flex flex-column">
      <div className='d-flex justify-content-center py-3'>
        <Image  src='/logo-hunter-hire-icono.svg' alt='logo-hunter' width={'45px'}></Image>
      </div>

      <div className='py-5'>
        <div className='d-flex justify-content-center'>
          <CorpProyects showText={false} centerTable={centerTable}></CorpProyects>
          
        </div>

        <div className='d-flex justify-content-center py-2'>
          <CorpCandidates showText={false} centerTable={centerTable}></CorpCandidates>
        </div>

        <div className='d-flex justify-content-center py-2'>
          <Tooltip title="Notifications" placement='right' arrow>
          <CorpTeam showText={false} centerTable={centerTable}></CorpTeam>
          </Tooltip>
        </div>

        <div className='d-flex justify-content-center py-2'>
          <CorpMessages showText={false} centerTable={centerTable}></CorpMessages>
        </div>

        
        <div className='d-flex justify-content-center py-2'>
          {/* <FontAwesomeIcon icon={faCircleDollarToSlot} title='Invoices' style={{color:'white' , height:25}}/> */}
          <CorpInvoices showText={false} centerTable={centerTable}></CorpInvoices>
        </div>

        <div className='d-flex justify-content-center py-2'>
          {/* <FontAwesomeIcon icon={faMessage} title='Messages' style={{color:'white' , height:25}}/> */}
          <CorpHunters showText={false} centerTable={centerTable}></CorpHunters>
        </div> 

      </div>        
    </div>
    </Container>
  


  )
}
