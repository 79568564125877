import React from 'react'

import Top from './Top'

import Left2 from './Left2'

import SuperCenter from './SuperCenter'

export default function DashAdminCorp() {
  return (

    <div className='m-0 small bg-right'> 
      <div className='dash-grid'>
        <header className='dash-header' >

          <Top name={"Dhruv"}></Top>

        </header>
        <section className='dash-left' style={{display:'inline-block'}} >
          <Left2></Left2>
        </section>
      
        <main className='dash-main'>
          <SuperCenter  ></SuperCenter> 
        </main>
      

      </div>
    </div>





  )
}
