import React  from 'react'


import { Tooltip } from '@mui/material';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCircleUser, faXmark } from '@fortawesome/free-solid-svg-icons'


export default function UserMenu( { showText , centerTable } ) {


  function setCenterTable(  )
  {
      centerTable( "UserTable") ;
  }


  return (
    <div>
        <div className='drop-item' onClick={setCenterTable}>
        <Tooltip title="Users" placement='right' arrow>
            <FontAwesomeIcon  icon={faCircleUser}  
              style={{color:'white' , height:25 , display:'inline'}}/>
          </Tooltip>
          { showText  &&    (  <p style={{display:'inline'}}>  &nbsp; &nbsp;Add User</p> ) }
        </div>        
    </div>
  )
}
