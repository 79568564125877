
import Carousel from 'react-grid-carousel'


import TipBanner from './TipBanner';



export default function TipBannerCarousel ( ) {




  return (
    <Carousel cols={1} rows={1} gap={20} loop  autoplay={3000}	>
      <Carousel.Item>
        <TipBanner index={1} width="100%"></TipBanner>
      </Carousel.Item>
      <Carousel.Item>
        <TipBanner index={2}> </TipBanner>
      </Carousel.Item>
      <Carousel.Item>
        <TipBanner index={3}></TipBanner>
      </Carousel.Item>
      <Carousel.Item>
        <TipBanner index={4}></TipBanner>
      </Carousel.Item>
      <Carousel.Item>
        <TipBanner index={5}></TipBanner>
      </Carousel.Item>
      <Carousel.Item>
        <TipBanner index={6}></TipBanner>
      </Carousel.Item>

 
    </Carousel>
  )
}