import React from 'react'

import TopRight from './TopRight'

import {  Row , Col ,Image} from 'react-bootstrap'
//import { BsEmojiSmile } from 'react-icons/bs'
import { FiAtSign } from 'react-icons/fi'
//FiAtSign

// BsEmojiSmile

export default function Top({name='none'}) {
  return (

    <div className='container-fluid d-flex align-items-center header'>
      
      <div className='d-flex w-100 justify-content-between align-items-center'>
          <div className='' >                    
                    <Image src='/smile-icon.svg' alt=""></Image> <div style={{display:'inline'}} > Hiii!</div> 
                    <FiAtSign></FiAtSign> <div style={{display:'inline'}} >{name}</div>
            </div>            

            <div className=''>
              <TopRight></TopRight>
            </div>

      </div>
    </div>


    // <div className='container-fluid header ' 
    //     >

    //    <Row className=' align-content-center'>

    //         <Col className="col-1">
    //         </Col>

    //         <Col className='col-3 flex-column'>
    //             <div className=' pt-4 align-content-center' >
                    
    //                 <Image src='/smile-icon.svg' alt=""></Image> <div style={{display:'inline'}} > Hi!</div> 
    //                 <FiAtSign></FiAtSign> <div style={{display:'inline'}} >{name}</div>
    //             </div>
    //         </Col>

    //         <Col className="col-5">
    //         </Col>

    //         <Col className='col-3 flex-column  pt-3'>
    //             <TopRight></TopRight>
                
    //         </Col>

    //    </Row>


    // </div>
  )
}
