//import logo from './logo.svg';

import { useEffect , useState } from 'react';

import './App.css';

//import DashAdminCorp from './Corporate/DashAdminCorp2';

import DashAdmin from './Dashboard/AdminPanel/DashAdmin/DashAdmin';
import Dashboard2 from './Dashboard/AdminPanel/Dashboard2';
import DashAdminCorp from './Corporate/DashAdminCorp';

import { BrowserRouter, Route, Routes } from "react-router-dom";

import CheckSession from './AppSession';
import AppNoSession from './AppNoSession';


import {  Cookies  } from 'react-cookie';



function App() {

  const [isValid,setIsValid] = useState(false) ;
  const [firstName,setFirstName] = useState( '') ;
  const [role,setRole] = useState(-1) ; // Aqui tenemos el rol del usuario
  //const [cookies, setCookie, removeCookie] = useCookies(['hhsession']);
  


   useEffect( () => {

      const queryParameters = new URLSearchParams(window.location.search)

      var session = queryParameters.get("session") ;

      console.log( "session = " + session ) ;

      if ( session === null )
      {
          console.log( "No venia con la session")
          setIsValid( false ) ;

          return ;
      }

      var retVal ;

      const cookies = new Cookies();

      CheckSession( session )
      .then((response) =>{

        retVal = response ;

        console.log( "La respuesta en then = " + JSON.stringify( retVal ) ) ;
        console.log( "production = " + process.env.production) ;

        //var tmpSession =  Cookies.get( "session") ;


        console.log( "value = " + retVal.value + " isCheck = " + retVal.isCheck ) 

        if ( retVal.value === true && retVal.isCheck === 0 ) // Es la primera vez
        {
          cookies.set('session', session, { path: '/' });
          setIsValid( true ) ;
          setFirstName( retVal.first ) ;
          setRole( retVal.role ) ;
        }
        else{
          var tmpSession = cookies.get( 'session' ) ;

          console.log( "tmpsession = " +  tmpSession  + " session = " +  session ) ;

          if (  tmpSession.toString() === session  )
          {
            console.log( "setIsValue = true ")
            setFirstName( retVal.first ) ;
            setIsValid( true ) ;
            setRole( retVal.role ) ;
            
            return ;
          }

          console.log( "setIsValue = false ")

          setIsValid( false ) ;
        }

      })
      .catch((err) => {

        console.log( err ) ;

        setIsValid( false ) ;



      }) ; // Vamos a verificar la sesion



   },[])



    //queryParameters.delete( 'session') 
  

  return (
    <>
    
    { !isValid && ( <AppNoSession></AppNoSession> ) }
   
   { isValid &&
   (
    <BrowserRouter>


      { role === 7 && (

          <Routes> 
            <Route path="/" Link="/Corporate/DashAdminCorp" element={<DashAdminCorp user={firstName}/>}  />
          </Routes>

      )
      }



      { role === 8 && (

        <Routes> 
          <Route path="/" Link="/Dashboard/AdminPanel/Dashboard2" element={<Dashboard2 user={firstName}/>}  />
        </Routes>

        )
      }

      { role === 1 && (

        <Routes>
          <Route path="/" Link="/Dashboard/AdminPanel/DashAdmin/DashAdmin" element={<DashAdmin user={firstName}/>} />
        </Routes>

        )

      }

    </BrowserRouter> ) }

    </>
  );
}

export default App;
