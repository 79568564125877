import React from 'react'

import { Row , Col } from 'react-bootstrap'

import SuperCenterTop from './SuperCenterTop'

import RightNews from './SuperComponents/RightNews'

import SuperCenterMiddle from './SuperCenterMiddle'

import ChartAccount from './SuperComponents/ChartAccount'

import NewProyects from './SuperComponents/NewProyects'

import TipBanner from './SuperComponents/TipBanner'

import TipBannerCarousel from './SuperComponents/TipBannerCarousel'

import ChatTopLeft from './SuperComponents/ChartTopLeft'
import ProyectStatus from './SuperComponents/ProyectStatus'

export default function SuperCenter() {
  return (
    <>


      
     <div className='contenedor-main py-3' style={{width:'100%' }} >

      
        <div className=' dash-vertical2' >

            <div className='box-stop box-2' style={{height:'190px'}} >
              <SuperCenterTop></SuperCenterTop>
            </div>

            <div className='box-chart box-2' style={{height:'420px'}}>
              <h2 className='h2 font-bold'>Account Statistics</h2>
                <ChatTopLeft className='mt-0'></ChatTopLeft> 
            </div>

            

            <div className='box-project-status box-2' style={{height:'420px'}}>

              <ProyectStatus ></ProyectStatus>
            </div>          

            <div className='box-projects box-2'>
              <NewProyects></NewProyects>
            </div>

            {/* <div className=' box-news p-2 mb-5' style={{ height:'100%' }} > */}

              <div className='box-news box-2' >

                <RightNews></RightNews>

                <div className='box-status'>
                  <TipBannerCarousel></TipBannerCarousel>
                </div>

              </div>
           
              
              
            {/* </div>
            */}
              
            <div className=' box-status box-2'>
              <ChartAccount ></ChartAccount>
            </div>
            
            
          </div>
      </div>
    </>
  )
}
