import React from 'react'

import { Image } from 'react-bootstrap'


export default function RightNewaItem(props) {
  return (
  <div>
    <div className='d-flex gap-3 w-100 news-item'>

      <div style={{display:'inline-block'}}>

      <Image src='/user-perfil-icono.svg' size='30px' alt='' style={{width:'30px'}}></Image>

      </div>

      <div>
        <div>
          <div>
            <p className='body-4 font-bold'>Hire User</p>
          </div>
          <div >
            <p className='body-5'>{props.message}</p>
          </div>
        </div> 
 
    </div>
  
    </div>
    <hr></hr>   
  </div>
  )
}
