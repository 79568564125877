import React from 'react'

//import { Row , Col } from 'react-bootstrap'

import SuperCenterTop from './SuperCenterTop'

import RightNews from './RightNews'
import ProyectStatus from './ProyectStatus'


//import RightNews from '../SuperComponents/RightNews'
//import RightNews from './RightNews'

//import SuperCenterMiddle from './SuperCenterMiddle'
//import SuperCenterMiddle from './SuperCenterMiddle'

//import ChartAccount from './ChartAccount'

import NewProyects from './NewProyects'
//import ProyectStatus from './ProyectStatus'


//import NewRegisters from './NewRegisters'
//import ChatTopLeft from './ChartTopLeft'


//import CorpSuperStats from './CorpSuperStatsus'
import CorpProyectStatus from './CorpProyectStatus'
import CorpStatistics from './CorpStatistics'

import ChartAccount from './ChartAccount'


import TipBannerCarousel from './TipBannerCarousel'





export default function SuperCenter() {
  return (
    <>

<div className='contenedor-main py-3'  style={{width:'100%' }}>

      
<div className=' dash-vertical2' >
    <div className='box-stop box-2' >
      <SuperCenterTop></SuperCenterTop>
    </div>

    <div className='box-chart box-2' style={{height:'425px' }}>
      <h2 className='h2 font-bold'>Account Statistics</h2>
        <CorpStatistics className='mt-0'></CorpStatistics>
    </div>

    <div className='box-project-status box-2' style={{height:'425px' }}>
      <ProyectStatus ></ProyectStatus>
    </div>          

    <div className='box-projects box-2' style={{ height : '320px'}}>
      <NewProyects></NewProyects>
    </div>

    {/* <div className=' box-news p-2 mb-5' style={{ height:'100%' }} > */}
      <div className='box-news box-2'>
        <RightNews></RightNews>

        <div className='box-status'>
          <TipBannerCarousel></TipBannerCarousel>
        </div>

      </div>
   
      
      
    {/* </div>
    */}
      
    <div className=' box-status box-2'>
      <ChartAccount ></ChartAccount>
    </div>
    
    
  </div>
</div>

    </>
  )
}
