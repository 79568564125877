import React , { useState} from 'react'

import { Row , Col } from 'react-bootstrap'

import Dropdown from 'react-bootstrap/Dropdown';

import ChartAccountDetails from './ChartAccountsDetails';

import ChartAccountStats from './ChartAccountStats';

export default function ChartAccount() {

    const [option,setOption] = useState( 0 ) ;

    const periodArray = [" (Last 30 Days)" , " (Last 60 Days)" , " (Last 6 Monts)"] ;

    function primero()
    {
        setOption( 0 ) ;
    }

    function segundo()
    {
        setOption( 1 )
    }

    function tercero()
    {
        setOption( 2 ) ;
    }

  return (

    <div className='d-flex flex-column'>
        <div className='mb-2'>
                <h2 className='h2 font-bold'>Account Details{periodArray[option]}</h2>
        </div>
        <div>            
            <div className='d-flex'>                
                <div style={{width:'180px' , height:'190px'}}>
                    <ChartAccountDetails index={option}></ChartAccountDetails>
                </div>
                    <div>
                        <Dropdown className='mb-5' >
                            <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{width:'100%' , fontSize : 12 }}>
                                Select Period
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={primero} >Last 30 Days</Dropdown.Item>
                                <Dropdown.Item onClick={segundo} href="#/action-2">Last 60 Days</Dropdown.Item>
                                <Dropdown.Item onClick={tercero} href="#/action-3">Last 6 Months</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>



                        <ChartAccountStats className=' ps-1' index={option}></ChartAccountStats>
                </div>
                </div>                
        </div>
    </div>

  )
}
