import React , {useState, useEffect} from 'react'

import Email from '../../../../UserElements/Email'
import Password from '../../../../UserElements/Password'
import FirstlastName from '../../../../UserElements/FirstLastName'

import Phone  from '../../../../UserElements/Phone'
import Address from '../../../../UserElements/Address'
import Company from '../../../../UserElements/Company'
import ErrorMessage from '../../../../UserElements/ErrorMessage'


import { Row , Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

import logohh from '../../../../images/logo-hh-login.svg'


import Select , {  } from 'react-select';


export default function UserAdd( {closeTable}) {

    const [isErr,setIsErr] = useState( false ) ;
    const [options,setOptions] = useState([]) ;
    const [optionPosition,setOptionPosition] = useState( -1 ) ; // No se ha seleccionado posicion

    function valideateErr()
    {
        setIsErr( true ) ;
    }

    function onSubmitHandler(event){

        event.preventDefault() ;

        console.log( "onSubmitHandler") ;

    }


    function setClose()
    {
      closeTable() ;
    }


    useEffect(() =>{

        loadOptions() ; // Lo llamamos al cargar el componente

    } ,[])

    function loadOptions()
    {

        var theArr = [
            {
                value : 1 ,
                label : "Associate" 
            } ,
            {
                value : 2 ,
                label : "Mid-Senior level" 
            } ,
            {
                value : 3 ,
                label : "Director" 
            } ,
            {
                value : 4 ,
                label : "Executive" 
            } ,



        ]

        var arr = [] ;


        var obj = {} ;

        obj.value = 1 ;
        obj.label = "Associate" ;

        arr.push( obj ) ;

        obj.value = 2 ;
        obj.label = "Mid-Senior level" ;

        arr.push( obj ) ;


        obj.value = 3 ;
        obj.label = "Director" ;

        arr.push( obj ) ;


        obj.value = 4 ;
        obj.label = "Executive" ;

        arr.push( obj ) ;


    
    
        setOptions( theArr ) ;


    }

  return (

    <div className='d-flex flex-column align-items-center'>
    <div className='my-5'>
        <a href='#'><img ms-auto src={logohh} alt="logohh"/></a>
    </div>



    
    <div className=" d-flex flex-column justify-content-center box-1 backemployer-2 mb-5"  > 

        <Row>
            <Col className='col-2'>
                <h4>Add User</h4>
            </Col>
            <Col className='col-9'>
            </Col>
            <Col className='col-1'>
                <FontAwesomeIcon className='pt-2 ps-2' onClick={setClose} icon={faXmarkCircle} 
                title='Close' style={{color:'blue' , height:25 }}/>
            </Col>

        </Row>

        

        <form className="pt-2 ps-5 form-2" onSubmit={onSubmitHandler}>

            <div className="row">
                <div className='col-sm'>
                    <Email  onError={valideateErr}/>
                </div>
                <div className='col-sm'>
                    <Password maxLength={50} size={50} />
                </div>

            </div>
            <FirstlastName maxLength={60} size={60}/>
            <Phone maxLength={18} size={18} />
            <Address/>


            <div className='row mt-6'>
                <div className='col-sm-6'>
                    <div className='form-group'>

                        <label htmlFor="position">Position</label>
                        
                            <Select 
                            // defaultValue={selectedOptions}
                            onChange={setOptionPosition}
                            // onInputChange={onInputChangeHandler}
                            options={options}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            
                            id="industry"
                            />

                    </div>

                </div>
	        </div>



            <hr/>
            <h4>Company</h4>
            <Company isIndustry="true"/>
            <button className="btn btn-primary mt-3 mb-3 button-3">Submit</button>
            { isErr && <ErrorMessage title={"este es el titulo"} message={"Este es el mensaje"} /> }

        </form>
    </div>
    </div>
  )
}
