import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsersRectangle} from '@fortawesome/free-solid-svg-icons'

const messages = [
    "Answer misery adieus add wooded how nay men before though. Pretended belonging contented mrs suffering favourite you the continual" ,
    "Bringing unlocked me an striking ye perceive. Mr by wound hours oh happy. Me in resolution pianoforte continuing we" ,
    "Placing assured be if removed it besides on. Far shed each high read are men over day. Afraid we praise lively he suffer family estate is" ,
    "Arrival entered an if drawing request. How daughters not promotion few knowledge contented" ,
    "He went such dare good mr fact. The small own seven saved man age ﻿no offer. Suspicion did mrs nor furniture smallness" ,
    "Talking chamber as shewing an it minutes. Trees fully of blind do. Exquisite favourite at do extensive listening"

]


export default function TipBanner(props) {
  return (
    <div className='box-8' style={{height:'118px' , borderRadius:'10px'}}>
        <div style={{display:'inline-block'}}>
            <FontAwesomeIcon icon={faUsersRectangle} color='yellow'></FontAwesomeIcon>
        </div>

        {" "}

        <div className='mb-0' style={{display:'inline-block'}}>
        
            <p className='mb-0' style={{fontFamily:'satoshi-bold' , color:'white' , height: '25px' }}>TipBanner ({props.index})</p> 
        </div>

        <div className='mb-0' style={{fontSize:'12px' , fontFamily:'satoshi-regular' , color:'white'}}>
            {/* <p className='mb-0'>Inform your users here of updates, trends or any news thet You want to give</p> */}
            <p className='mb-0'>{messages[props.index-1].substring(0,65) + "..."}</p>

        </div>

        <div className=' align-content-center'>
            <button  className=' button-4'>
                Send
            </button>
        </div>
        
        
    </div>
  )
}
