import React from 'react'


import { Row , Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'


const data = [

    [
        { name: 'Accepted', value: 400 },
        { name: 'Pending', value: 300 },
        { name: 'Reject', value: 300 },
        //{ name: 'Group D', value: 200 },
      ],
      [
        { name: 'Accepted', value: 300 },
        { name: 'Pending', value: 300 },
        { name: 'Reject', value: 300 },
        //{ name: 'Group D', value: 300 },
      ],
      [
        { name: 'Accepted', value: 200 },
        { name: 'Pending', value: 100 },
        { name: 'Reject', value: 100 },
        //{ name: 'Group D', value: 50 },
      ]

]



export default function ChartAccountStats(props) {
  return (
    <div className=' ms-2'>
        <Row style={{width:'100%'}}>

            <Col className='col-1'>
                <FontAwesomeIcon icon={faCircle} color='purple'></FontAwesomeIcon>
            </Col>

            <Col  className='col-8 me-0 ' >
                <p className='body-3'>Accepted</p>
            </Col>

            <Col className='col-1'>
                {data[props.index][0].value}
            </Col>

        </Row>

        <Row style={{width:'100%'}}>

            <Col className='col-1'>
                <FontAwesomeIcon icon={faCircle} color='cyan'></FontAwesomeIcon>
            </Col>

            <Col className='col-8 me-0'>
                <p>Pending</p>
            </Col>



            <Col className='col-1'>
                {data[props.index][1].value}
            </Col>
        </Row>
        <Row style={{width:'100%'}}>
            <Col className='col-1'>
                <FontAwesomeIcon  icon={faCircle} color='pink'></FontAwesomeIcon>
            </Col>

            <Col  className='col-8 me-0'>
                <p>Reject</p>
            </Col>



            <Col className='col-1'>
                {data[props.index][2].value}
            </Col>        

        </Row>
    </div>
  )
}
