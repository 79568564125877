import React from 'react'

import { Row , Col } from 'react-bootstrap'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle , faClock , faCircleQuestion , faCircleCheck , faCircleXmark } from '@fortawesome/free-solid-svg-icons'

import NewProyectsDetails from './NewProyectsDetails'

const feeRange = [
    ["28" , "85" , "105"],
    ["22" , "60" , "75"],
    ["20" , "70" , "85"],
    ["18" , "90" , "105"],
    ["24" , "65" , "95"],
    ["27" , "58" , "75"]
  ]

  const listleft = [

    ["4" , "1" , "3"],
    ["3" , "0" , "2"],
    ["5" , "2" , "4"],
    ["2" , "1" , "1"],
    ["3" , "2" , "2"],
    ["1" , "0" , "0"]

  ]

  const listRight = [

    ["6" , "3" , "12"],
    ["5" , "0" , "4"],
    ["8" , "2" , "10"],
    ["5" , "0" , "8"],
    ["6" , "2" , "12"],
    ["3" , "1" , "5"]

  ]

  const listPosted = [
    " 3 days ago " ,
    " 1 week ago " ,
    " yesterday " ,
    " today " ,
    " one Month ago " ,
    " 6 hours ago "
  ]

  
  


export default function NewProyectsItem( props ) {
  return (

    <div className='box-4' style={{width:'auto'}}>
        <div className='d-flex flex-column gap-2'>
            <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faCircle} color='#61E294'></FontAwesomeIcon> 
                    <p className='body-3 font-bold ps-2'>{props.sector}</p>
                </div>
                <FontAwesomeIcon icon={faCircleQuestion} color='grey-dark'></FontAwesomeIcon>             
            </div>
            <div className='d-flex'>
                <FontAwesomeIcon icon={faClock}  style={{color:'#A5A5A5' , height:'15px'}}></FontAwesomeIcon>
                <p className='body-4 tx-color-gray-dark ps-2'>Posted {listPosted[props.fee]} by Employeer</p>
            </div>
        
            <div className='text-center box-9'>
                <p className='m-0'>{props.industry}</p>
            </div> 

            <div>
                <p className='body-3 font-bold'>Fee: 
                    <span> {feeRange[props.fee][0]}%</span>  of {feeRange[props.fee][1]},000 - {feeRange[props.fee][2]},000</p>
            </div>
            <div>
            <NewProyectsDetails fee={props.fee}></NewProyectsDetails>
            </div>
            <div className='d-flex justify-content-center gap-4'>
                    <div>
                        <FontAwesomeIcon icon={faCircleCheck} style={{color:'#61E294' , height:'30px'}}></FontAwesomeIcon>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faCircleXmark} style={{color:'#F500FF' , height:'30px'}}></FontAwesomeIcon>
                    </div>
            </div>
        </div>
       
    </div>
  )
}

