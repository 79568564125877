import React from 'react'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock , faUser } from '@fortawesome/free-solid-svg-icons'

export default function SuperGoogStats() {
  return (

    <div className='d-flex flex-column'>
        <div className='d-flex flex-column'>     
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon className=' dash-middle ' icon={faClock} style={{color:'#19A5F4' , height:15}} />
            <p className='body-2 m-0 ps-2'>$20k-$50k</p>
          </div>          
          <p className='body-4 font-regular m-0 p-0 tx-color-black' >Money Gain</p>
        </div>
        
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon className=' dash-middle' icon={faUser} style={{color:'#19A5F4' , height:15}} />
            <p className='body-2 m-0 ps-2'>4 of 10</p>          
          </div> 
          <p className='body-4 font-regular m-0 p-0 tx-color-black' >Active Proyects</p>        
        </div>

        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon className='dash-middle' icon={faUser} style={{color:'#19A5F4' , height:15}} />
            <p className='body-2 m-0 ps-2'>4 of 10</p>
          </div>
          
          <p className='body-4 font-regular m-0 p-0 tx-color-black' >Closed Proyects</p>
        </div>
        {/* <p className=' font-medium m-0 p0' >250</p> */}     


    </div>
  )
}
