import React from 'react'

import { Row , Col } from 'react-bootstrap'

import SuperCenterTop from './SuperCenterTop'


//import RightNews from '../SuperComponents/RightNews'
import RightNews from './RightNews'

//import SuperCenterMiddle from './SuperCenterMiddle'
import SuperCenterMiddle from './SuperCenterMiddle'

import ChartAccount from './ChartAccount'

import NewProyects from './NewProyects'
import ProyectStatus from './ProyectStatus'


import NewRegisters from './NewRegisters'
import ChatTopLeft from './ChartTopLeft'




export default function SuperCenter() {
  return (
    <>

      <div className='contenedor-main py-3'  style={{width:'100%' }}>
      
        <div className='dash-vertical'>
          <div className='box-stop box-2' >
            <SuperCenterTop></SuperCenterTop>
          </div>

          <div className='box-chart box-2' style={{maxWidth:'100%'}}>
                <h2 className='h2 font-bold'>Account Statistics</h2>
                <ChatTopLeft className='mt-0'></ChatTopLeft> 
          </div>
          <div className='box-registers box-2'>
              <NewRegisters ></NewRegisters>
          </div>

          <div className='box-projects box-2'>            
            <NewProyects></NewProyects>
          </div>

            <div className='box-news box-2'>
              <RightNews></RightNews>
            </div>

            {/* <TipBannerCarousel className=" mb-2" style={{borderRadius:'10px'}}></TipBannerCarousel> */}

            
            <div className='box-status box-2'>
              {/* <ChartAccount  ></ChartAccount> */}
              <ProyectStatus></ProyectStatus>
            </div>           
      

        </div>

        
          
          
        
      </div>
    </>
  )
}
