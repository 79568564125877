import React , { useState} from 'react'


import Company from '../../../../../src/UserElements/Company'

// D:\hunterhire\nexjjs\huntelfull\hunterhire\src\UserElements

import { Row , Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faXmarkCircle } from '@fortawesome/free-solid-svg-icons'


import logohh from '../../../../images/logo-hh-login.svg'


export default function CompanyAdd({closeTable}) {

  const [CompanyData,setCompanyData2] = useState({})



   function setCompanyData( value ) // Aqui recol
   {

     console.log(  "En company Add" + JSON.stringify( value ) ) ;

     setCompanyData2( value ) ;

   }

    function setClose()
    {
      closeTable() ;
    }


    function onSubmit()
    {
      console.log( "Company submit") ;
    }


  return (

    <div className='d-flex flex-column align-items-center'>
    <div className='my-5'>
        <a href='#'><img ms-auto src={logohh} alt="logohh"/></a>
    </div>



    <div className='d-flex flex-column justify-content-center box-1 backemployer-2 mb-5'>


        <Row>
            <Col className='col-2'>
                <h4>Add Company</h4>
            </Col>
            <Col className='col-9'>
                
            </Col>
            <Col className='col-1'>
                <FontAwesomeIcon className='pt-2 ps-2' onClick={setClose} icon={faXmarkCircle} 
                title='Close' style={{color:'blue' , height:25 }}/>
            </Col>

        </Row>
            
        <Company isIndustry="true" setCompanyData={setCompanyData}></Company>
        <Button className='button-3 mt-3' onClick={onSubmit}>Submit</Button>
    </div>
    </div>
  )
}
