import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

export default function SuperGoodJob() {
  return (

    <div className='box-3 d-flex flex-column justify-content-evenly'>
      <div className='body-0 tx-color-cyan font-bold text-center'>
        <div className='d-flex align-items-center'>
          <p className='m-0'>4.5</p>           
          <FontAwesomeIcon icon={faStar} style={{color:'#7507FA' , height:12}}/>
        </div>          
          <p className='body-4 font-regular tx-color-black'>Good Job!</p>
        </div>

    </div>
 
  )
}
