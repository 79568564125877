import React , { useMemo , useEffect  , useState } from 'react'

import MaterialReactTable from 'material-react-table';


import { MenuItem } from '@mui/material';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faXmarkCircle } from '@fortawesome/free-solid-svg-icons'



import { Row , Col  } from 'react-bootstrap';

function setData( val ) // No hace nada , solo de comodin
{

}

function getRow( )
{
  if ( true )
  {
    return <>
      <Row>
        <Col className='col-2'>
          {/* <button onClick={setClose}>Close Users</button> */}
        <FontAwesomeIcon className='pt-2 ps-2'  icon={faXmarkCircle} 
          title='Users' style={{color:'blue' , height:25 , display:'inline'}}/>
        </Col>

        <Col className='col-3'>
        </Col>

        <Col>
          <h3>Users</h3>
        </Col>

      </Row></>
  }
  else{
    return <></>
  }
}


export default function NewProyectsList({data}) {


    const columns = useMemo(
        () => [
            {
            accessorKey: 'name', //access nested data with dot notation
            header: 'Name',
            size: 150,
            },
            {
            accessorKey: 'positions_number',
            header: 'Position Number',
            size: 150,
            },
            {
            accessorKey: 'status', //normal accessorKey
            header: 'Status',
            size: 200,
            },
            {
            accessorKey: 'fee',
            header: 'Fee',
            size: 150,
            },
            {
            accessorKey: 'range_salary',
            header: 'Range_salary',
            size: 100,
            },
            {
            accessorKey: 'company',
            header: 'Company',
            size: 100,
            },
            {
            accessorKey: 'username',
            header: 'User Name',
            size: 200,
            }
        
        
        ],
        [],
        );

  return (
    <div>

{/* {getRow()} ; */}


<MaterialReactTable
enableRowActions

enableMultiRowSelection enableRowSelection enableColumnResizing columns={columns} data={data}   muiTableHeadCellProps={{
  //easier way to create media queries, no useMediaQuery hook needed.
  sx: {
    fontSize: {
      xs: '10px',
      sm: '11px',
      md: '12px',
      lg: '13px',
      xl: '14px',
    },
  },
}} muiTableBodyCellProps={{  sx: {
  fontSize: {
    xs: '10px',
    sm: '11px',
    md: '12px',
    lg: '13px',
    xl: '14px',
  },
},}} 

renderRowActionMenuItems={({ table , row }) => [
  <MenuItem key="edit" onClick={() => table.setEditingRow(row)}>
    Edit
  </MenuItem>,
  <MenuItem key="add" onClick={() => table.setEditingRow(row)}>
    Add
  </MenuItem>,
  <MenuItem key="delete" onClick={() => { data.splice(row.index, 1); //assuming simple data table

  setData([...data]);}}>
    Delete
  </MenuItem>,
]}



/>



        {/* <ul>

            {
                setData.map( ( item , index ) =>{
                    return (
                        <li>
                            <p>{item.name}</p>
                        </li>
                    )

                })
            }

        </ul> */}

    </div>
  )
}
