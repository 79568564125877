import React , {  useState } from 'react'


import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome'
import {  faCircleDollarToSlot } from '@fortawesome/free-solid-svg-icons'


import { Tooltip } from '@mui/material';





export default function InvoicesMenu( {  centerTable} ) {

  const [theOpen,setTheOpen] = useState( false ) ;


  function setCenterTable(  )
  {
      centerTable( "InvoicesTable") ;
  }



  return (
    <div  >

      
        <div className=' drop-item' onClick={setCenterTable}>
          <Tooltip title="Invoices" placement='right' arrow>
            <FontAwesomeIcon  icon={faCircleDollarToSlot} title='Invoices' style={{color:'white' , height:25}}/>
          </Tooltip>

        </div>
        


    </div>
  ) }