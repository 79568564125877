import React from 'react'

import { Row , Col, Button} from 'react-bootstrap'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import NewRegItem from './NewRegItem'

export default function NewRegisters() {
  return (
    <div>

        <div>
            <div className='d-flex justify-content-between'>

                <div className='d-flex gap-2'>
                    <h2 className='h2 font-bold'>New Registers</h2>
                    <div className='' style={{color:'white'}}>
                    <div className='circle-not'>10</div>
                </div>

                </div>
            <div className='d-flex gap-2'>
                <div>
                    <button className='button-3'>View All</button>
                </div>

                <div>
                    <button  className='button-5'>Most Recent</button>
                </div>
            </div>


            </div>
        </div>
            
        <div className='mt-2' style={{overflowY:'auto', height:'250px' , overflowX :'hidden'}}>
            <Row>
                <NewRegItem name={"Diana"} lastname={"Hamilton"} position={"Sales Director"} eltime={"10 min"} email={'diana@gmail.com'} phone={'55201277'}></NewRegItem>
                
            </Row>               

            <Row>
                <NewRegItem name={"Waseem"} lastname={"Ahmed"} position={"Software Engeneer"} eltime={"2 hours"} email={'wassen@gmail.com'} phone='5521226063'></NewRegItem>
            </Row>

                

            <Row>
                <NewRegItem name={"Robert"} lastname={"Oaks"} position={"Tech Director"} eltime={"1 Day"}  email={'robert@gmail.com'} phone={'871128280'}></NewRegItem>
            </Row>

            <Row>
                <NewRegItem name={"Daniele"} lastname={"Rosa"} position={"Assintant"} eltime={"1 Day"} email={'daniele@gmail.com'} phone={'34567889'}></NewRegItem>
            </Row>

            <Row>
                <NewRegItem name={"Ana"} lastname={"Velazco"} position={"Programmer"} eltime={"1 Day"} email={'ana@gmail.com'} phone={'3333456786'}></NewRegItem>
            </Row>

            <Row>
                <NewRegItem name={"Kyaw"} lastname={"Min"} position={"Art Designer"} eltime={"2 Days"} email={'kyawa@gmail.com'} phone={'2214653412'}></NewRegItem>
            </Row>


        </div>

    </div>
  )
}
