import React from 'react'

import TopRight from './TopRight'

import {  Row , Col ,Image} from 'react-bootstrap'
//import { BsEmojiSmile } from 'react-icons/bs'
import { FiAtSign } from 'react-icons/fi'
//FiAtSign

// BsEmojiSmile

export default function Top( {centerTable ,user }) {



  return (
    <div className='container-fluid d-flex align-items-center  header'>

       <div className='d-flex w-100 justify-content-between align-items-center'>
              <div className='' >
                    {/* <BsEmojiSmile   > </BsEmojiSmile> */} 
                    <Image src='/smile-icon.svg' alt=""></Image> <div style={{display:'inline'}} > Hi!</div> 
                    <FiAtSign></FiAtSign> <div style={{display:'inline'}} >{user}</div>
              </div>

            <div className=''>
                <TopRight centerTable={centerTable}></TopRight>
            </div>

       </div>


    </div>
  )
}
