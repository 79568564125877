import React, { useState } from 'react';
import { EditorState , convertToRaw} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import draftToHtml from 'draftjs-to-html';

// import dynamic from 'next/dynamic'

// const Editor = dynamic(
//   () => import('react-draft-wysiwyg').then((mod) => mod.Editor),
//   { ssr: false }
// )



//import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
//import './App.css';

function App({title="Hola esta es una prueba" , help="Ayuda" , updateme } ) {
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  function onEditorSetChange( e )
  {
    setEditorState( e ) ;

    const editorHTML = draftToHtml(convertToRaw(e.getCurrentContent()));

    console.log( editorHTML ) ;//
    
    updateme( editorHTML ) ;


  }

  return (
    <div className="App">
      <header title={help} className="App-header-word" style={{height:'40px' , fontSize:'20px'}}>
        {title}
      </header>

      <Editor 
          editorState={editorState}
          // onEditorStateChange={setEditorState}
          onEditorStateChange={onEditorSetChange}
          toolbar={{
            options: ['inline', 'blockType', 'list' ,  'textAlign',  'link', 'embedded',  'image', 'remove', 'history'],
        
            blockType: {
              inDropdown: true,
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
              // className: undefined,
              // component: undefined,
              // dropdownClassName: undefined,
            },
          }}
          wrapperClassName="wrapper-class"
          // editorClassName="editor-class"
          editorClassName="wrapperClassName "

          editorStyle={{lineHeight: '85%'}}
 
          toolbarClassName="toolbar-class"
      />
    </div>
  )
}

export default App;