import React from 'react'

//import { Row , Col } from 'react-bootstrap'

//import SuperGoodJob from './SuperComponents/SuperGoodJob'
//import SuperGoodJob from './SuperGoodJob'
//import SuperGoogStats from './SuperComponents/SuperGoogStats'
//import SuperGoogStats from './SuperGoogStats'

import SuperTopRightProps from './SuperTopRightProps'
import CorpSuperStats from './CorpSuperStats'

export default function SuperCenterTopLeft() {
  return (
    <section>

        <div className=''>
            <h2 className='h2 font-bold'>Performance</h2>
        </div>

        <div className='d-flex gap-3'>

            <div className=''>
                <SuperTopRightProps amount="3.25" title="Gain" isUp={false}></SuperTopRightProps>
            </div>

            <div className=''>
                {/* <SuperTopRightProps amount="23" title="Accounts Payable" isUp={true}></SuperTopRightProps>*/}
                <CorpSuperStats ></CorpSuperStats> 
 
            </div>

            {/* <div className=''>
                <SuperTopRightProps amount="35" title="New Companies" isUp={false}></SuperTopRightProps>
            </div> */}

            

        </div>

    </section>
  )
}
