import React from 'react'


import { Row , Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'


export default function ProyectStatus() {
  return (

    <div className='d-flex flex-column h-100'>

        <div>
            <h2 className='h2 font-bold'>Proyect Status</h2>
        </div>

        <div className='mt-4'>


            <div>
                <div className='d-flex justify-content-between'>
                    <p className='body-3 circle-green'>Open</p>
                    <p className='body-3'>10 </p>               
                </div> 
            </div>


            <hr></hr>

            <div>
                <div className='d-flex justify-content-between'>
                    <p className='body-3 circle-orange'>On Hold</p>
                    <p className='body-3'> 21 </p>
            </div>

            <hr></hr>
            
            <div>
                <div className='d-flex justify-content-between'>
                    <p className='body-3 circle-pink'>Canceled</p>
                    <p className='body-3'>05</p>
                </div>                
            </div>
            
            <hr></hr>

            <div>
                <div className='d-flex justify-content-between'>
                    <p className='body-3 circle-purple'>Closed</p>
                    <p className='body-3'>05</p>
                </div>
            </div>


            <hr></hr>

            <div>

                <div className='d-flex justify-content-between'>
                    <p className='body-3 font-bold'>Efficiency</p>
                    <p className='body-3 font-bold'>80%</p>
                </div>


            </div>
        </div>
        </div>
    
    </div>

  )
}
