import React , { useState} from 'react'

import { Row , Col /*, Carousel */} from 'react-bootstrap'

//import Carousel01 from './Carousel01'
import Carousel01 from './Carousel01'

//import NewProyectsUpRight from './NewProyectsUpRight'
import NewProyectsUpRight from './NewProyectsUpRight'
import NewProyectsList from './NewProyectsList';


export default function NewProyects() {

  const [isCarousel,setIsCarousel] = useState(true)  ;
  const [data,setData] = useState([]) ;

  function theChange(value)
  {
    console.log( "Llegando onChange = " + value ) ;

    if ( value === "showgrid")
    {
      setIsCarousel( false ) ;
      return ;
    }
    setIsCarousel( true ) ;

  }

  function getData( data )
  {
      setData( data ) ;
  }

  return (
    <div>
        <div className='d-flex justify-content-between'>

            <div className='d-flex gap-2'>
                <h2 className='h2 font-bold'>New Proyects</h2>
                <div className='circle-not'>10</div>
            </div>

            <div>

              <NewProyectsUpRight change={theChange}></NewProyectsUpRight>
                
            </div>



        </div>

        <Row className='pt-2' style={{width:'100%'}}>

           <Carousel01 getData={getData}></Carousel01> 

            {/* { isCarousel ? <Carousel01 getData={getData}></Carousel01> : <NewProyectsList data={data}></NewProyectsList>}
             */}

        </Row>

    </div>
  )
}
