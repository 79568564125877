import React from 'react'

import { Row , Col } from 'react-bootstrap'


const listleft = [

    ["4" , "1" , "3"],
    ["3" , "0" , "2"],
    ["5" , "2" , "4"],
    ["2" , "1" , "1"],
    ["3" , "2" , "2"],
    ["1" , "0" , "0"]

  ]

  const listRight = [

    ["6" , "3" , "12"],
    ["5" , "0" , "4"],
    ["8" , "2" , "10"],
    ["5" , "0" , "8"],
    ["6" , "2" , "12"],
    ["3" , "1" , "5"]

  ]


export default function NewProyectsDetails(props) {
  return (

    <div className='box-7 p-0' style={{height:'100px'}}>

    <div style={{display:'inline-block' , width : '49%' , verticalAlign:'top'}}>

        <Row className=' mt-1 ps-2' style={{height:'25px'}}>
            <p className='body-5  tx-color-purple'>Hunters</p>
        </Row>

        <Row className='ps-2' style={{height:'25px'}}>
            <p className='body-4'>{listleft[props.fee][0]} Suppliers</p>
        </Row>
        <Row className=' ps-2 ' style={{height:'25px'}}>
            <p className='body-4'>{listleft[props.fee][1]} Request</p>
        </Row>
        <Row className=' ps-2 ' style={{height:'25px'}}>
            <p className='body-4'>{listleft[props.fee][2]} Views</p>
        </Row>


    </div>

    <div style={{borderLeft : '1px solid rgb(211,211,211)' , height : '100%' , display:'inline-block'}}></div>

    <div style={{display:'inline-block' , width : '45%' , verticalAlign:'top' }}>

        <Row className=' mt-1 ps-1' style={{height:'25px'}}>
            <p className='body-5  tx-color-purple'>Candidates</p>
        </Row>

        <Row className='ps-2' style={{height:'25px'}}>
            <p className='body-4'>{listRight[props.fee][0]} Active</p>
        </Row>
        <Row className=' ps-2 ' style={{height:'25px'}}>
            <p className='body-4'>{listRight[props.fee][1]} Call for Interview</p>
        </Row>
        <Row className=' ps-2 ' style={{height:'25px'}}>
            <p className='body-4'>{listRight[props.fee][2]} Inactive</p>
        </Row>

    </div>
    
    </div>

    // <div className='box-7 p-0' style={{height:'100px'}}>

    //     <div style={{display:'inline-block' , width : '49%' , verticalAlign:'top'}}>

    //         <Row className=' mt-1 ps-2' style={{height:'25px'}}>
    //             <p className='body-5  tx-color-purple'>Hunters</p>
    //         </Row>

    //         <Row className='ps-2' style={{height:'25px'}}>
    //             <p className='body-4'>{listleft[props.fee][0]} Suppliers</p>
    //         </Row>
    //         <Row className=' ps-2 ' style={{height:'25px'}}>
    //             <p className='body-4'>{listleft[props.fee][1]} Request</p>
    //         </Row>
    //         <Row className=' ps-2 ' style={{height:'25px'}}>
    //             <p className='body-4'>{listleft[props.fee][2]} Views</p>
    //         </Row>


    //     </div>

    //     <div style={{borderLeft : '1px solid rgb(211,211,211)' , height : '100%' , display:'inline-block'}}></div>

    //     <div style={{display:'inline-block' , width : '45%' , verticalAlign:'top' }}>

    //         <Row className=' mt-1 ps-1' style={{height:'25px'}}>
    //             <p className='body-5  tx-color-purple'>Candidates</p>
    //         </Row>

    //         <Row className='ps-2' style={{height:'25px'}}>
    //             <p className='body-4'>{listRight[props.fee][0]} Active</p>
    //         </Row>
    //         <Row className=' ps-2 ' style={{height:'25px'}}>
    //             <p className='body-4'>{listRight[props.fee][1]} Call for Interview</p>
    //         </Row>
    //         <Row className=' ps-2 ' style={{height:'25px'}}>
    //             <p className='body-4'>{listRight[props.fee][2]} Inactive</p>
    //         </Row>

    //     </div>
        
    // </div>
  )
}
