import React , { useState , useRef , useEffect } from 'react'

import {Image} from 'react-bootstrap'


import DropPlus from './Plus/DropPlus'


//import CandidatesModal from './CandidatesModal';




export default function TopRight({centerTable}) {

    const refOne = useRef(0 )
    const refTwo = useRef(0)


    const [drop,setDrop] = useState(false) ;


    const [isListen,setIsListen] = useState( true )
    const [theOpen,setTheOpen] = useState( false ) ;

    useEffect( () => {
        console.log( "addEventListener")

        //setIsListen( true ) 

        //document.addEventListener( "click" , handleOutside ,  false ) ;

        //refTwo.addEventListener( "click" , handleOutside ,  false )

        document.getElementById( 'myImage').addEventListener( "click" , handleOutside ,  false  )

        setTimeout( () => console.log( "isListen = " + isListen ) , 5000 ) ;
    } ,[ ])


    // document.removeEventListener("mousedown", handleMouseDown, true); // Succeeds

    function openModal()
    {
        setTheOpen( true ) ;
    }

    function stopListen(val)
    {

        console.log( "Llegando stopListen " + val ) ;

        //document.removeEventListener("click", handleOutside);

        if ( val )
        {
            setIsListen( false ) ;

            setTheOpen( true ) ;

 
            setTimeout( () => console.log( "stopListen = " + isListen ) , 5000 ) ;

        }else{

            console.log( "Lo regresamos a true ") ;
            setIsListen( true ) ;

        }
        


        console.log( "Despues de stopListen")
    }





    const handleOutside = (e) => {

            e.preventDefault() ;

            console.log( "handleOutside drop = " + drop )

            if ( ( refTwo.current.contains( e.target ) ) /* && !drop */)
            {
                console.log( "Lo activamos " + drop ) ;
                setIsListen( false ) ;
                setTheOpen( false ) ;
                setDrop( true ) ;
 
                return ;
            }

            if ( ( !refOne.current.contains( e.target  ) )  )
            {
                console.log( "Estamos fuera") ;
                setDrop( false ) ;
                return ; 
            }

    }



    function closeModal( ){

        console.log( "closeModal = " + drop ) ;

        setTheOpen( false ) ;
        setDrop( false ) ;
    }






  return (

    <div>

        <div className='d-flex alig-items-center'>

            <div className=''>                
                <div className="input-group" >
                    <div className="form-outline w-100 input-sm" style={{display:'inline-block'}}>
                        <input type="search" id="search001" className="form-control top-search button-3 " size='sm' placeholder='Seach Oportunity' style={{color:'black' , display:'inline-block'}}/>                           
                    </div> 
                </div>                

            </div>
            <div className='d-flex align-items-center ps-3'>            
                <div>
                    <Image ref={refTwo} id='myImage' src='/action-icon.svg' size='30px' alt=''
                      style={{width:'30px'}}/*onClick={theClick}*/ ></Image>

                      
                        { drop && !theOpen && ( <div ref={refOne}><DropPlus className='pt-0' stop={stopListen} 
                           centerTable={centerTable} ></DropPlus> </div>)   }
                      
                  
                </div>
            </div>

            <div className='d-flex align-items-center ps-3'>            
                <div >
                    <Image src='/user-perfil-icono.svg' size='30px' alt='' style={{width:'30px'}}></Image>
                </div>
            </div>


        </div>


        {/* <CandidatesModal  theOpen={theOpen} closeModal={closeModal} ></CandidatesModal> */}

        
    </div>
    
  )
}
