import React , { useEffect , useState , useRef , useMemo } from 'react'
import { Form , Row , Col} from 'react-bootstrap'

//import { useRouter } from 'next/router'


import axios from 'axios' ;

import getGlobal from '../setglobal';

import PostVacancy2 from './PostVacancy2';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faXmarkCircle } from '@fortawesome/free-solid-svg-icons'


import logohh from '../images/logo-hh-login.svg'


import JoditEditor from 'jodit-react';






function countriesCb(response){

    var mySelect = document.getElementById('country') ;

    if ( mySelect.length > 0 )
    {
      return ;
    }

    for (var i = 0; i < response.length; i++) {
      var option = document.createElement("option");
      option.value = response[i].id.toString();
      option.text = response[i].name ;
      mySelect.appendChild(option);
    }

    if ( response.length > 0 )
    {
        getStates( response[0].id.toString() ) ; // Aaqui cargamos los estados
    }

    //CompensationsApi.getCompensations(compensationsCb)

  }


  function getCountries()
  {


    //axios.get('http://localhost:3000/api/Data/getcountries')
    axios.get( getGlobal() + 'getcountries')
    .then(response => {
      //console.log( JSON.stringify(response ) ) ;

      console.log( "countries") ;

      countriesCb( response.data ) ;

    

    })
    .catch(e => {
      console.log( "countries") ;
      console.log( e ) ;
    })
    .finally(  () => {


  })

}


function removeOptions(selectElement) {
    var i, L = selectElement.options.length - 1;
    for(i = L; i >= 0; i--) {
       selectElement.remove(i);
    }
 }

 function citiesCb( response )
 {
    var mySelect2 = document.getElementById('city') ;

    if ( mySelect2.length > 0 )
    {
      removeOptions( mySelect2 ) ;
    }

    for (var i = 0; i < response.length; i++) {
      var option = document.createElement("option");
      option.value = response[i].id.toString();
      option.text = response[i].name ;
      mySelect2.appendChild(option);
    }


 }

 function getCities( id )
 {
    console.log( "Llamando a cities = " + id ) ;

    //axios.get("http://localhost:3000/api/Data/getcities?id=" + id )
    axios.get( getGlobal() + "getcities?id=" + id )
    .then(response => {
      //console.log( JSON.stringify(response ) ) ;

      console.log( "cities") ;

      citiesCb( response.data ) ;

    })
    .catch(e => {
      console.log( "countries") ;
      console.log( e ) ;
    })
    .finally(  () => {


  })
 }
 
 function statesCb(response)
 {
     var mySelect2 = document.getElementById('state') ;

     if ( mySelect2.length > 0 )
     {
       removeOptions( mySelect2 ) ;
     }

     for (var i = 0; i < response.length; i++) {
       var option = document.createElement("option");
       option.value = response[i].id.toString();
       option.text = response[i].name ;
       mySelect2.appendChild(option);
     }

     if ( response.length > 0 )
     {
         //setNState( response[0].id.toString() ) ;
         getCities( response[0].id.toString() ) ; // Aaqui cargamos las ciudades
     }


 }




function getStates(id)
  {

    console.log( "Llamando a states = " + id ) ;


    


    //axios.get("http://localhost:3000/api/Data/getstates?id=" + id )
    axios.get( getGlobal() + "getstates?id=" + id )
    .then(response => {
      //console.log( JSON.stringify(response ) ) ;

      console.log( "countries") ;

    

      statesCb( response.data ) ;

    })
    .catch(e => {
      console.log( "countries") ;
      console.log( e ) ;
    })
    .finally(  () => {


  })

}



function stateChange( e )
{

    getCities( e.target.value ) ;
}








export default function PostVacancy({closeTable }) {

    function setClose()
    {
      closeTable() ;
    }


    const editor = useRef(null);
    const editor2 = useRef( null) ;
    const [content, setContent] = useState('');
    const [content2, setContent2] = useState('');


    const config = { readonly : false , placeholder : "Srart Typings..."}
  
    // const config = useMemo(
    //   {
    //     readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    //     placeholder: placeholder || 'Start typings...'
    //   },
    //   [placeholder]
    // );
  


    const [show2,setShow2] = useState(false) ;

    const [count,setCount] = useState(0) ;

    const [jobTitle2 , setJobTitle] = useState("Job Title") ;
    const [nOpennings,setnOpennings] = useState(0) ;
    const  [nCountry,setNCountry] = useState(0) ;
    const  [nState,setNState] = useState(0) ;
    const  [nCity,setNCity] = useState(0) ;

    const jobtitleRef = useRef( ) ;
    const nofOpenningsRef =useRef() ;
    const countryRef = useRef() ;
    const stateRef = useRef() ;
    const cityRef = useRef() ;


    function countryChange(e)
    {
        console.log( "value = " + e.target.value ) ;
        setNCountry( e.target.value ) ;

        getStates( e.target.value ) ;
    }


    useEffect(()=>{
        getCountries() ;
        //setShow2(false) ;
        console.log( "useEfeect") ;
      } , [] ) ;

      useEffect(()=>{
        console.log( "useEfect => Count ") ;

        if ( count === 0 )
        {
          return ;
        }
        var jt = document.getElementById( "jobtitle") ;

        jt.value = jobTitle2 ;

      } , [count,jobTitle2] ) ;

  //const router = useRouter() ;


  const onNext = (e) => {

    console.log( "onNext") ;
  
    e.preventDefault() ;

    localStorage.setItem( 'page1' , fillPage1() ) ;

    setShow2(true) ;
 
  }

  function fillPage1() // Hacemos el llenado de la pagina 1
  {
    var data = {
      jobTitle : jobtitleRef.current.value ,// document.getElementById("jobtitle").value ,
      numberOfOpennings : nofOpenningsRef.current.value , // document.getElementById("typeNumber").value ,
      country : countryRef.current.value , // document.getElementById("country").value ,
      state :  stateRef.current.value , // document.getElementById("state").value ,
      city : cityRef.current.value // document.getElementById("city").value 

    }

    console.log( "data = " +  JSON.stringify( data ) ) ;

    return JSON.stringify( data ) ;

  }


  const gPage1 = () => {
    console.log( "Hola") ;

    //setJobTitle( "Director") ;

    setCount( count + 1 ) ;

   setShow2( false ) ;

   console.log( "Regresando = " +  localStorage.getItem('page1' )   ) ;

   const retValue = JSON.parse( localStorage.getItem('page1' ) ) ;

   setJobTitle( retValue.jobTitle ) ;
   setnOpennings( retValue.numberOfOpennings ) ;

   setNCity( retValue.city ) ;
   setNState( retValue.state ) ;
   setNCountry( retValue.country ) ;

  }

  

// d-flex flex-column justify-content-center box-1 backemployer-2 mb-5
// container justify-content-center backemployer-2
// style={{height: "100vh"} }

// htmlFor="flexRadioDefault1"
  return (

  
    <>
    { (!show2) && 

    (
    
      <div className='d-flex flex-column align-items-center'>
        <div className='my-5'>
          <a href='#'><img ms-auto src={logohh} alt="logohh"/></a>
        </div>
    
      <div className='d-flex flex-column justify-content-center box-1 backemployer-2 mb-5'  id="page1"  >


        <Row >
          <Col className='col-2'>
              <h4 >Add Candidate</h4>
          </Col>
          <Col className='col-9'>
          </Col>
          <Col className='col-1'>
              <FontAwesomeIcon className='pt-2 ps-2' onClick={setClose} icon={faXmarkCircle} 
              title='Close' style={{color:'blue' , height:25 }}/>
          </Col>

        </Row>
       
        <Form className='pt-2 ps-5  form-2' onSubmit={onNext}   >

            <div className='row'>

                <div className='col-sm'>
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="jobtitle">Job Title</label>
                        <input type="text" className="form-control" id="jobtitle"  
                        placeholder="Job Title" value={jobTitle2} 
                        onChange={(e) => setJobTitle(e.target.value)} ref={jobtitleRef}/>
                        
                    </div>
                </div>
                <div className='col-sm align-content-right'>
                    <div className="form-outline" style={{width: "100%" , height : "10px"  }}>
                        <label className="form-label m-0" htmlFor="typeNumber">Number of Opennings</label>
                        <input    type="number" id="typeNumber " min={0} max={20}
                        className="form-control p-lg-2" ref={nofOpenningsRef}
                        onChange={(e) => setnOpennings(e.target.value)} value={nOpennings}/>
                    </div>
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-sm'>
                <div className='form-group'>

                    <label htmlFor="country">Country</label>

                    <select className="form-control w-40" id="country" 
                    type="select" maxLength={50} onChange={countryChange} ref={countryRef} value={nCountry}>

                    </select>

                </div>

                </div>
            

                <div className='col-sm'>
                    <div className='form-group'>

                        <label htmlFor="state">State</label>

                        <select className="form-control w-40" id="state" type="select" 
                        maxLength={50} onChange={stateChange} ref={stateRef} value={nState}>

                        </select>

                    </div>
                </div>
                

                <div className='col-sm'>
                <div className='form-group'>

                    <label htmlFor="city">City</label>

                    <select className="form-control w-40" id="city" type="select" maxLength={50} ref={cityRef}
                    value={nCity} onChange={console.log("hola")}>

                    </select>

                </div>
                </div>
            </div>

            <div className='row' >
                <div className='col-12'>
                    <div className="form-group" style={{width: "100%"  }} >
                        <label htmlFor="responsabilities">Job Title</label>
                        {/* <textarea  className="form-control form-control-sm" id="responsabilities"  rows="2" /> */}

                        <JoditEditor
                          id="responsabilities"
                          ref={editor}
                          value={content}
                          config={config}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={newContent => {}}
                        />
                        
                    </div>
                </div>
            </div>

            <div className='row' >
                <div className='col-12'>
                    <div className="form-group" style={{width: "100%"  }} >
                        <label htmlFor="requiredexperience">Required Experience</label>
                        {/* <textarea  className="form-control form-control-sm" id="requiredexperience"  rows="2" /> */}
                        <JoditEditor
                          id="requiredexperience"
                          ref={editor2}
                          value={content2}
                          config={config}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newContent => setContent2(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={newContent => {}}
                        />

                    </div>
                </div>
            </div>

            <div className='row' >
                <div className='col-12'>
                    <div className="form-group" style={{width: "100%"  }} >
                        <label htmlFor="keyquestions">Key Questions</label>
                        <textarea  className="form-control form-control-sm" id="keyquestions"  rows="2" />
                        
                    </div>
                </div>
            </div>

            <div className='row mt-2'>
              <div className='col-sm'>
                    <div className='form-goup'>

                        <label htmlFor="paymentduedates">Payment Due Date</label>

                        <select className="form-control w-40" id="paymentduedates" type="select" onChange={console.log("Hola")}>
                          <option value="1">1-7 Days</option>
                          <option value="2">8-30 Days</option>
                          <option value="3">31-60 Days</option>
                          <option value="4">61-90 Days</option>
                          <option value="5">90+ Days</option>
                          <option value="5">Other</option>

                        </select>

                        

                    </div>
              </div>

              <div className='col-sm '>

              <label >Pyment Installments</label>



                <div className='align-items-center'>

                

                <div className="form-check">
                  <label className="form-check-label "  htmlFor="flexRadioDefault1">
                    Yes
                  </label>                  
                  <input className="form-check-input radio"  type="radio" name="payment" id="flexRadioDefault1"/>

                </div>
                <div className="form-check">
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    No
                  </label>                  
                  <input onChange={console.log("onChange")} className="form-check-input radio"  type="radio" name="payment" id="flexRadioDefault2" />

                </div>
                
                </div>

              </div>
            </div>




            <div className='row' >
                <div className='col-12'>
                    <div className="form-group" style={{width: "100%"  }} >
                        <label htmlFor="paymenttermsandconditions">General Payment Terms & Conditions</label>
                        <textarea  className="form-control form-control-sm" id="paymenttermsandconditions"  rows="2" />
                        
                    </div>
                </div>
            </div>

            <div className='row' >
                <div className='col'>
                    <div className="form-group" style={{width: "100%"  }} >
                        <label htmlFor="targetcompanies">Target Comnpanies</label>
                        <textarea  className="form-control form-control-sm" id="targetcompanies"  rows="2" />
                        
                    </div>
                </div>

                <div className='col'>
                    <div className="form-group" style={{width: "100%"  }} >
                        <label htmlFor="offlimitcompanies">Off-Limit Companies</label>
                        <textarea  className="form-control form-control-sm" id="offlimitcompanies"  rows="2" />
                        
                    </div>
                </div>

            </div>

            <button type="submit" className="btn btn-primary mt-3 button-3" >Next</button>



        </Form>

        
    </div> </div>)
    }
    { show2 && (<PostVacancy2 id="page2" gotoPage1={() => gPage1() } />) }
    
    </>
  )
}
