import React  from 'react'


import { Row , Col } from 'react-bootstrap'

import SuperTopRightProps from './SuperComponents/SuperTopRightProps'



export default function SuperCenterTopRight() {



  return (

    <div>

        <div className='' >
            <p className='h2 font-bold'>Vacancies Statistics</p>
        </div>
        <div className='d-flex gap-3'>

            <div className=''>
                <SuperTopRightProps amount="3.25" title="Accepted" isUp={false}></SuperTopRightProps>
            </div>

            <div className=''>
                <SuperTopRightProps amount="40" title="Pending" isUp={true}></SuperTopRightProps>
            </div>

            <div className=''>
                <SuperTopRightProps amount="13" title="Rejected" isUp={false}></SuperTopRightProps>
            </div>

            <div className=''>
                <SuperTopRightProps amount="25" title="In Interview" isUp={true}></SuperTopRightProps>
            </div>

            <div className=''>
                <SuperTopRightProps amount="25" title="On Hold"></SuperTopRightProps>
            </div> 

        </div>

       
    </div>
  )
}
