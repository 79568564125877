import React  from 'react'


import { Tooltip } from '@mui/material';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faUserGear } from '@fortawesome/free-solid-svg-icons'


export default function CorpHunters( { showText , centerTable } ) {


  function setCenterTable(  )
  {
      centerTable( "Hunters") ;
  }


  return (
    <div>
        <div className='drop-item' onClick={setCenterTable}>
        <Tooltip title="Hunters" placement='right' arrow>
            <FontAwesomeIcon  icon={faUserGear}  
              style={{color:'white' , height:25 , display:'inline'}}/>
          </Tooltip>
          { showText  &&    (  <p style={{display:'inline'}}>  &nbsp; &nbsp;Hunters</p> ) }
        </div>        
    </div>
  )
}
