import React  from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFolderOpen } from '@fortawesome/free-solid-svg-icons'

import { Tooltip } from '@mui/material';


export default function VacancyMenu( { showText = false , stop = null ,  centerTable , isAdd = false} ) {

  // function setClose()
  // {
  //   closeTable() ;

  // }

  function setCenterTable(  )
  {
      if ( isAdd === true )
      {
        console.log( "Llamando a vacancy add") ;
        centerTable( "VacancyAdd") ;
      }
      else{
        centerTable( "Vacancy") ;
      }

      

      if( stop !== null )
      {
        stop(true) ;
      }
      
  }


  return (
    <div>
        <div className=' drop-item' onClick={setCenterTable}>
          <Tooltip title="Vacancy" placement='right' arrow>
            <FontAwesomeIcon   icon={faFolderOpen} style={{color:'white' , height:25 , display:'inline'}}/>
          </Tooltip>

          { showText  &&    (  <p style={{display:'inline'}}>  &nbsp; &nbsp;  &nbsp;Add Vacancy</p> ) }
        </div>
    </div>
  )
}