import React  from 'react'


import { Tooltip } from '@mui/material';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'


export default function CorpMessages( { showText , centerTable } ) {


  function setCenterTable(  )
  {
      centerTable( "Team") ;
  }


  return (
    <div>
        <div className='drop-item' onClick={setCenterTable}>
        <Tooltip title="Messages" placement='right' arrow>
            <FontAwesomeIcon  icon={faBell}  
              style={{color:'white' , height:25 , display:'inline'}}/>
          </Tooltip>
          { showText  &&    (  <p style={{display:'inline'}}>  &nbsp; &nbsp;Team</p> ) }
        </div>        
    </div>
  )
}
