import React from 'react'

import axios from 'axios'

import getGlobal from '../../../../setglobal'

export default function VacancyAddAxios( parms , descripcions) {

    return new Promise( ( resolve , reject ) => {

        console.log( "Entrando a axios")

        var retVal = validateData( parms ) ;

        if ( retVal.stat === false )
        {
            resolve( retVal ) ; // Detectamos error y regresamos
            return ;
        }


        console.log( "Antes de llamar a postvacancy" + getGlobal() + "postvacancy") ;

        var theData =  buildData(parms,descripcions) ;

        console.log( JSON.stringify(theData))

        axios.post( getGlobal() + "postvacancy" , theData , {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Content-Type': 'application/json'
            }
          })
        .then( (response) => {

            console.log( "regresando ") ;

            console.log( "********HHHH*********" +  JSON.stringify( response ))
            resolve( "ok ") ;

        }).catch( (e) => {
            console.log( e ) ;
            resolve( "error") ;
        })

        

    })

}

function buildData( parms , descripcions)
{
    var data = {
        
    }

    data.name = parms.posName.value ; // Este es el nombre en l abase de datos
    data.position_number = parms.positions.value ;
    data.location = parms.state.value ;
    data.responsabilities = parms.responsabilities.value ;
    data.required_experience = parms.requiredExperience.value ;
    data.key_position_questions = parms.keyPositionsQuestions.value ;
    data.file_job_description = descripcions.jobDesc ;
    data.file_employeer = descripcions.fileEmployeer ;
    data.quali = descripcions.quali ;
    data.additional = descripcions.additional ;
    data.industry = descripcions.industry ;
    data.search_type = descripcions.search_type ;
    data.contract_type = descripcions.contract_type ;
    data.years_experience = descripcions.years_experience ;
    data.range_salary = descripcions.range_salary ;
    data.replacement_period = descripcions.replacement_period ;
    data.general_conditions = parms.generalConditions.value ;
    data.warrantyEmployeer = parms.warrantyEmployeer.value ;
    data.fee = parms.fee.value ;
    data.terms = parms.terms.value ;
    data.vacancy_status_id = descripcions.vacancy_status_id ;
    data.created_at = getFormatDate( new Date() ) ; // Falta la funcon de la fecha de hoy
    data.updated_at = getFormatDate( new Date() ) ; 
    data.company_id = 1 ; // Corregir ( se debera traer con los datos originales) 
    data.country_id = descripcions.country_id ;
    data.state_id = descripcions.state_id ;
    data.city_id = descripcions.city_id ;
    data.read_status = "unread" ;
    data.due_date = descripcions.due_date ;
    data.other = parms.other.value ;

    return data ;
}

function validateData( parms ) // Aqui validamos que los datos esten correctos
{
    var retVal = {
        stat : true 
    }

    return retVal ; // De prueba lo pasamos
}

function getFormatDate(d) {
    var date = new Date(d);
    var dateStr =
        date.getFullYear() + "-" +
        ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
        ("00" + date.getDate()).slice(-2) + " " +
    	("00" + date.getHours()).slice(-2) + ":" +
    	("00" + date.getMinutes()).slice(-2) + ":" +
    	("00" + date.getSeconds()).slice(-2);
    //console.log(dateStr);

    return dateStr;
}