import React  from 'react'


import { Row , Col } from 'react-bootstrap'

//import SuperTopRightProps from './SuperComponents/SuperTopRightProps'
import SuperTopRightProps from './SuperTopRightProps'



export default function SuperCenterTopRight() {



  return (
    <div >
        <div className='' >
            <h2 className='h2 font-bold'>User Statistics</h2>
        </div>
        <div className='d-flex gap-3'>

            <div className=''>
                <SuperTopRightProps amount="12" title="Active Proyects" isUp={false}></SuperTopRightProps>
            </div>

            <div className=''>
                <SuperTopRightProps amount="325" title="Active Candidates" isUp={true}></SuperTopRightProps>
            </div>

            <div className=''>
                <SuperTopRightProps amount="40" title="Pending Candidates" isUp={false}></SuperTopRightProps>
            </div>

            <div className=''>
                <SuperTopRightProps amount="25" title="In Interview Candidates" isUp={true}></SuperTopRightProps>
            </div>
            <div className=''>
                <SuperTopRightProps amount="10" title="Average Time days of Closed Proyects" isUp={true}></SuperTopRightProps>
            </div>

 

        </div>
       
    </div>
  )
}
