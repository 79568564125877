import React from 'react'

import { Row , Col } from 'react-bootstrap'


import SuperCenterTopLeft from './SuperCenterTopLeft'

import SuperCenterTopRight from './SuperCenterTopRight'


export default function SuperCenterTop() {
  return (



    <div className='w100 body-4 '>

    <div className='d-flex gap-3'>
          <div>
              <SuperCenterTopLeft></SuperCenterTopLeft>
          </div>
          <div>
              <div className="vl"></div>
          </div>
          <div>
              <SuperCenterTopRight></SuperCenterTopRight>
          </div>

      </div>
  </div>

  )
}
