import React , { useRef } from "react";

import Address2 from "./Address2";

import IndustriesAreas from "./IndustriesAreas";


function Company({isIndustry , setCompanyData , setAddressData }) {

    const companyName = useRef(null) ;
    const comercialName = useRef( null ) ;
    const description = useRef( null ) ;
    const companyEmail = useRef( null ) ;

    function onError( value )
    {
        console.log( "Se detecto error en el email")
    }

    function onChange()
    {

        

        var data =  {

            companyName : companyName.current.value ,
            comercialName : comercialName.current.value ,
            description : description.current.value ,
            companyEmail : companyEmail.current.value

        } 

        //console.log ( "CompanyData = " + data  )


        setCompanyData( data ) ;


    }

    function setDataAddress( value)
    {

        console.log( "Company llegado el data address") ;

        setAddressData( value ) ;

    }



    return (
    
        <div className="pt-2 form-2">

            <div className="row">
                <div className="col-sm">
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="companyname">Company Name</label>
                        <input ref={companyName} onChange={onChange} 
                        className="form-control" id="companyname" type="text" maxLength={80} />
                    </div>
                </div>

                <div className="col-sm">
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="comercialname">Comercial Name</label>
                        <input ref={comercialName} onChange={onChange} 
                        className="form-control" id="comercialname" type="text" maxLength={80} />
                    </div>
                </div>

            </div>


            <div className="row">

                <div className="col-sm">
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="description">Description</label>
                        <input ref={description} onChange={onChange} 
                        className="form-control" id="description" type="text" maxLength={80} size= {80}/>
                    </div>
                </div>

                <div className="col-sm">
                    <div className="form-group"  style={{width: "24rem"  }} >
                        <label htmlFor="companyemail">Company email</label>
                        <input ref={companyEmail} onChange={onChange}  
                        className="form-control myForm" id="companyemail" type="email" maxLength={80} size= {80}/>
                    </div>
                </div>

            </div>


            <Address2 setDataAdress={setDataAddress}/>
            {
                isIndustry ==="true" ? ( <div><label>Industries</label> <IndustriesAreas/> </div>): ""
            }
           


        </div>
    )

}

export default Company ;