import React , { useEffect , useState , useRef } from 'react'


import Select , {  } from 'react-select';

import { Row , Col, Form} from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'


import VacancyEditor from './VacancyEditor';

import axios from 'axios'

import getGlobal from '../../../../setglobal';

import VacancyAddPost from './VacancyAddPost';
import VacancyAddAxios from './VacancyAddAxios';

import logohh from '../../../../images/logo-hh-login.svg'


export default function VacancyAdd( { closeTable }) {
    const [options,setOptions] = useState([]) ;
    const [options2,setOptions2] = useState([]) ;
    const [options3,setOptions3] = useState([]) ;
    const [options4,setOptions4] = useState([]) ;
    const [options5,setOptions5] = useState([]) ;
    const [options6,setOptions6] = useState([]) ;
    const [options7,setOptions7] = useState([]) ;
    const [options8,setOptions8] = useState([]) ;


    const  [nCountry,setNCountry] = useState(0) ;
    const  [nState,setNState] = useState(0) ;
    const  [nCity,setNCity] = useState(0) ;

    const  [jobDesc,setJobDesc] = useState("<></>")
    const  [fileEmpl,setFileEmpl] = useState( "<></>")
    const  [quali,setQuali] = useState( "<></>") ;
    const  [additional,setAdditional] = useState( "<></>") ;

    const [optionIndustry,setOptionIndustry] = useState( -1 ) ;
    const [searchType,setSearchType] = useState( -1 ) ;
    const [contractType,setContractType] = useState( -1 ) ;
    const [yearsExperience,setYearsExperience] = useState( -1 ) ;
    const [rangeSalary,setRangeSalary] = useState( -1 ) ;
    const [repPeriod,setRepPeriod] = useState(-1) ;
    const [vacancyStatus,setVacancyStatus] = useState(-1) ;
    const [dueDate,setDueDate] = useState(-1) ;
    


    const countryRef = useRef() ;
    const stateRef = useRef() ;
    const cityRef = useRef() ;
   


    function getDueDates()
    {
        var items = [
            {
                value_id : 1 ,
                name : '1 - 7 days'

            },
            {
                value_id : 2 ,
                name : '8 - 30 days'

            },
            {
                value_id : 3 ,
                name : '31 -60 days'
            },
            {
                value_id : 4 ,
                name : '61 - 90 days'
            },
            {
                value_id : 5 ,
                name : '90+ days'
            },
            {
                value_id : 6 ,
                name : 'Other'
            },

        ] ;

        var arr = items.map( (item) => {

            var obj = {} ;
        
            obj.value = item.value_id ;
            obj.label = item.name ;
    
            return obj ;

        })

        setOptions8( arr ) ;

    }


    function citiesCb( response )
    {
       var mySelect2 = document.getElementById('city') ;
   
       if ( mySelect2.length > 0 )
       {
         removeOptions( mySelect2 ) ;
       }
   
       for (var i = 0; i < response.length; i++) {
         var option = document.createElement("option");
         option.value = response[i].id.toString();
         option.text = response[i].name ;
         mySelect2.appendChild(option);
       }
   
   
    }
   
    function getCities( id )
    {
       console.log( "Llamando a cities = " + id ) ;
   
       //axios.get("http://localhost:3000/api/Data/getcities?id=" + id )
       axios.get( getGlobal() + "getcities?id=" + id )
       .then(response => {
         //console.log( JSON.stringify(response ) ) ;
   
         console.log( "cities") ;
   
         citiesCb( response.data ) ;

         setNCity( id ) ;
   
       })
       .catch(e => {
         console.log( "cities") ;
         console.log( e ) ;
       })
       .finally(  () => {
   
        console.log( "getDueDates") ; 
        getDueDates() ; 
   
     })
    }
   



    function statesCb(response)
    {
        var mySelect2 = document.getElementById('state') ;
   
        if ( mySelect2.length > 0 )
        {
          removeOptions( mySelect2 ) ;
        }
   
        for (var i = 0; i < response.length; i++) {
          var option = document.createElement("option");
          option.value = response[i].id.toString();
          option.text = response[i].name ;
          mySelect2.appendChild(option);
        }
   
        if ( response.length > 0 )
        {
            //setNState( response[0].id.toString() ) ;
            getCities( response[0].id.toString() ) ; // Aaqui cargamos las ciudades
        }
   
   
    }
   
   
   
   
   function getStates(id)
     {
   
       console.log( "Llamando a states = " + id ) ;
   
   
       
   
   
       //axios.get("http://localhost:3000/api/Data/getstates?id=" + id )
       axios.get( getGlobal() + "getstates?id=" + id )
       .then(response => {
         //console.log( JSON.stringify(response ) ) ;
   
         console.log( "countries") ;
   
       
   
         statesCb( response.data ) ;

         setNState( id ) ;
   
       })
       .catch(e => {
         console.log( "countries") ;
         console.log( e ) ;
       })
       .finally(  () => {
   
   
     })
   
   }
   




    function countriesCb(response){

        var mySelect = document.getElementById('country') ;
    
        if ( mySelect.length > 0 )
        {
          return ;
        }
    
        for (var i = 0; i < response.length; i++) {
          var option = document.createElement("option");
          option.value = response[i].id.toString();
          option.text = response[i].name ;
          mySelect.appendChild(option);
        }
    
        if ( response.length > 0 )
        {
            getStates( response[0].id.toString() ) ; // Aaqui cargamos los estados
        }
    
        
    
      }


      function countryChange(e)
      {
          console.log( "value = " + e.target.value ) ;
          setNCountry( e.target.value ) ;
  
          getStates( e.target.value ) ;
      }
  
    function removeOptions(selectElement) {
    var i, L = selectElement.options.length - 1;
        for(i = L; i >= 0; i--) {
            selectElement.remove(i);
        }
    }
  
    function stateChange( e )
    {
    
        getCities( e.target.value ) ;

        setNState( e.target.value ) ;
    }
    

    function cityChange( e )
    {
        setNCity( e.target.value ) ;
    }
    
      function getCountries()
      {
    
    
        //axios.get('http://localhost:3000/api/Data/getcountries')
        axios.get(getGlobal() + 'getcountries')
        .then(response => {
          //console.log( JSON.stringify(response ) ) ;
    
          console.log( "countries") ;
    
          countriesCb( response.data ) ;
    
        
    
        })
        .catch(e => {
          console.log( "countries") ;
          console.log( e ) ;
        })
        .finally(  () => {
    
    
      })
    
    }
    
    



    function getVacancyStatus()
    {
        var items = [
            {
                value_id : 5 ,
                name : 'Active'

            },
            {
                value_id : 6 ,
                name : 'Pending'
            },
            {
                value_id : 7 ,
                name : 'Inactive'
            },
            {
                value_id : 8 ,
                name : 'Finished'
            },
        ] ;

        var arr = items.map( (item) => {

            var obj = {} ;
        
            obj.value = item.value_id ;
            obj.label = item.name ;
    
            return obj ;

        })

        setOptions7( arr ) ;

        getCountries() ; 

        

    }


    function getSalaryRanges()
    {

        var items = [
            {
                value_id : 7 ,
                name : 'Less than 1 year'

            },
            {
                value_id : 8 ,
                name : '1 to 2 years'
            },
            {
                value_id : 9 ,
                name : '3 to 5 years'
            },
            {
                value_id : 10 ,
                name : '6 to 10 years'
            },
            {
                value_id : 11 ,
                name : '10 to 15 years'
            },
            {
                value_id : 12 ,
                name : 'more than 15 years'

            }
        ] ;

        var arr = items.map( (item) => {

            var obj = {} ;
        
            obj.value = item.value_id ;
            obj.label = item.name ;
    
            return obj ;

        })

        setOptions4( arr ) ;

        getVacancyStatus() ;
     
    }


    function getReplacementPeriods()
    {

        function setReplacementPeriods( response )
        {
            var arr = response.map( function(item )  {

                var obj = {} ;
        
                obj.value = item.value_id ;
                obj.label = item.name ;
        
                return obj ;
            })
        
            setOptions6( arr ) ;
        }

        //axios.get(  'http://localhost:3000/api/Data/getreplacementperiods' )
        axios.get( getGlobal() + 'getreplacementperiods' )
        .then( response => {

            if ( response.data.length > 0 )
            {
                setReplacementPeriods( response.data ) ;
            }
            else{
                console.log( "getreplacementperiods No tenemos datos "  )
            }
        }).catch((e) =>  {

            console.log( e ) ;

        }).finally(() =>{

            getSalaryRanges() ;

        }
        )

    }




    function getCompensations()
    {
        function setCompensations( response )
        {
            var arr = response.map( function(item )  {

                var obj = {} ;
        
                obj.value = item.id ;
                obj.label = item.salary ;
        
                return obj ;
            })
        
            setOptions5( arr ) ;
        }

        //axios.get(  'http://localhost:3000/api/Data/getcompensations' )
        axios.get( getGlobal() + 'getcompensations' )
        .then( response => {

            if ( response.data.length > 0 )
            {
                setCompensations( response.data ) ;
            }
            else{
                console.log( "getTypeInvolvedSearch No tenemos datos "  )
            }
        }).catch((e) =>  {

            console.log( e ) ;

        }).finally(() =>{

            getReplacementPeriods() ;

        }
        )
    }




    function getContractTypes()
    {

        function setContractTypes(response)
        {
            var arr = response.map( function(item )  {

                var obj = {} ;
        
                obj.value = item.value_id ;
                obj.label = item.name ;
        
                return obj ;
            })
        
            setOptions3( arr ) ;
        }
    

        //axios.get(  'http://localhost:3000/api/Data/getcontracttypes' )
        axios.get( getGlobal() + 'getcontracttypes' )
        .then( response => {

          if ( response.data.length > 0 )
          {
              setContractTypes( response.data ) ;
          }
          else{
              console.log( "getTypeInvolvedSearch No tenemos datos "  )
          }

        }).catch((e) => {
            console.log( e ) ;
        }).finally( () => {
            getCompensations() ;
        })

    }


    function getTypeInvolvedSearch()
    {

        function setInvolvedSearch(response)
        {
            var arr = response.map( function(item )  {

                var obj = {} ;
        
                obj.value = item.value_id ;
                obj.label = item.name ;
        
                return obj ;
            })
        
            setOptions2( arr ) ;
    
        }

        //axios.get(  'http://localhost:3000/api/Data/gettypeinvolvedsearch' )
        axios.get( getGlobal() + 'gettypeinvolvedsearch' )
        .then( response => {

           

          if ( response.data.length > 0 )
          {
              setInvolvedSearch( response.data ) ;

              //console.log( "getTypeInvolvedSearch*** = " + response.data.length)
          }
          else{
              console.log( "getTypeInvolvedSearch No tenemos datos "  )
              
          }

        }
        )
        .catch( (e) => {

          console.log( e ) ;

      } ).finally( () => {
        getContractTypes() ;
      }

      )


    }

    function setIndustries( response )
    {

        var arr = response.map( function(item )  {

            var obj = {} ;
    
            obj.value = item.value_id ;
            obj.label = item.name ;
    
            return obj ;
        })
    
        setOptions( arr ) ;


    }

    function  getIndustries( )
    {
        //axios.get(  'http://localhost:3000/api/Data/getindustries' )
        axios.get( getGlobal() +  'getindustries' )
          .then( response => {

            if ( response.data.length > 0 )
            {
                setIndustries( response.data ) ;
            }
            else{
                console.log( "getIndustries No tenemos datos "  )
                
            }

          }
          )
          .catch( (e) => {

            console.log( e ) ;

        } ).finally( () => {
            getTypeInvolvedSearch() ;
        }

        )

        
    }


    useEffect( ()=>{

        getIndustries() ; // Solicitamos la lista de industrias

        //getSalaryRanges() ;
        

    } ,[])

    const [postState,setPostState] = useState( false ) ;

    function setClose()
    {
        closeTable() ;
    }

    const [pData,setPData] = useState( null) ; // Son los datos que vamos a postear

    async function onSubmit(event)
    {
        event.preventDefault() ;

        console.log( "postname = " + event.target.posName.value )
        

        setPData( event.target ) ;
        setPostState( true ) ; 

        var descriptions = {
            jobDesc : jobDesc ,
            fileEmployeer : fileEmpl ,
            quali : quali ,
            additional : additional ,
            industry : optionIndustry ,
            search_type : searchType ,
            contract_type : contractType ,
            years_experience : yearsExperience ,
            range_salary : rangeSalary ,
            replacement_period : repPeriod ,
            vacancy_status_id : vacancyStatus ,
            country_id : nCountry ,
            state_id : nState ,
            city_id : nCity ,
            due_date : dueDate
        }

        var retVal =  await VacancyAddAxios(event.target , descriptions ) ;

        console.log( "Saliendo de axios " + JSON.stringify( retVal ) ) ;

        setPostState( false ) ; // Apagamos la pantalla de fondo



    }

    function setnOpennings( value )
    {

    }

    function onUpdateJobDesc( theHtml )
    {
        setJobDesc( theHtml ) ;
    }

    function onUpdateFileEmployee( theHtml )
    {
        setFileEmpl( theHtml) ;
    }

    function onUpdateQualifications( theHtml )
    {
        setQuali( theHtml)

    }

    function onUpdateAdditional( theHtml )
    {
        setAdditional( theHtml )

    }

// container justify-content-center backemployer-2
  return (

    <div className='d-flex flex-column align-items-center'>
    <div className='my-5'>
        <a href='#'><img ms-auto src={logohh} alt="logohh"/></a>
    </div>


    <div className='d-flex flex-column justify-content-center box-1 backemployer-2 mb-5 form-2'  id="page1">

        <Row>
          <Col className='col-2'>
              <h4>Add Vacancy</h4>
          </Col>
          <Col className='col-9'>
            { pData && <VacancyAddPost  open={postState} func={setPostState} ></VacancyAddPost> }
          </Col>
          <Col className='col-1'>
              <FontAwesomeIcon className='pt-2 ps-2' onClick={setClose} icon={faXmarkCircle} 
              title='Close' style={{color:'blue' , height:25 }}/>
          </Col>

        </Row>


        <Form className='pt-1' onSubmit={onSubmit}   >

            <div className='row'>

                <div className='col-sm-4'>
                    <div className="form-group"  style={{width: "100%"  }} >
                        <label htmlFor="user">User</label>
                        <input type="text" className="form-control p-lg-2" id="user"  
                        placeholder="User" value="Adriana Castillo" readOnly
                        />
                        
                    </div>
                </div>
                <div className='col-sm-6 '>
                    <div className="form-outline" style={{width: "80%" , height : "30px"  }}>
                        <label className="form-label m-0" htmlFor="posName">Position name</label>
                        <input    type="text" id="posName" 
                        className="form-control p-lg-2" 
                        onChange={(e) => setnOpennings(e.target.value)} />
                    </div>
                </div>

                <div className='col-sm-2 align-content-right'>
                    <div className="form-outline" style={{width: "100%" , height : "30px"  }}>
                        <label className="form-label m-0" htmlFor="positions">Positions</label>
                        <input    type="number" id="positions" 
                        className="form-control p-lg-2" 
                        />
                    </div>
                </div>
            </div>





            <div className='row'>

                <div className='col-sm-6 '>
                    <div className="form-outline" style={{width: "100%"   }}>
                        <label className="form-label m-0" htmlFor="responsabilities">Responsabilities</label>
                        {/* <input    type="text" id="responsabilities" 
                        className="form-control p-lg-2" 
                        /> */}
                        <textarea class="form-control" id="responsabilities" rows="2"></textarea>
                    </div>


                </div>

                <div className='col-sm-6 align-content-right'>
                    <div className="form-outline" style={{width: "100%"   }}>
                        <label className="form-label m-0" htmlFor="requiredExperience">Required Experience</label>
                        {/* <input    type="text" id="requiredExperience" 
                        className="form-control p-lg-2" 
                        /> */}
                        <textarea class="form-control" id="requiredExperience" rows="2"></textarea>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-sm-6 align-content-right'>
                    <div className="form-outline" style={{width: "100%"   }}>
                        <label className="form-label m-0" htmlFor="keyPositionsQuestions">Key Positions Questions</label>
                        {/* <input    type="textarea" id="keyPositionsQuestions" 
                        className="form-control p-lg-2" 
                        /> */}
                        <textarea class="form-control" id="keyPositionsQuestions" rows="3"></textarea>
                    </div>
                </div>


            </div>


            <div className='row pt-4' style={{height:'40vh' , overflowY:'auto'}}>

                <div className='col-sm-12 '>
                    <div className="form-outline" style={{width: "100%"   }}>
                        {/* <label className="form-label m-0 font-bold" title='Describe the responsalities end keys to success de job' 
                        htmlFor="fileJobDescription">File Job Description</label> */}
                        <VacancyEditor id="fileJobDescription" title='File Job Description'
                          help='Describe the responsalities end keys to success de job' 
                          updateme={onUpdateJobDesc}></VacancyEditor>

                    </div>


                </div>

            </div>
            <div className='row pt-4' style={{height:'40vh' , overflowY:'auto'}}>
                <div className='col-sm-12 align-content-right'>
                    <div className="form-outline" style={{width: "100%"   }}>
                        {/* <label className="form-label m-0 font-bold" title='Describe what makes your company great'
                        htmlFor="fileEmployeer">File Employeer</label> */}
                        <VacancyEditor id="fileEmployeer"  title='File Employeer'
                          help='Describe what makes your company great'
                          updateme={onUpdateFileEmployee}></VacancyEditor>

                        {/* <input    type="file" id="fileEmployeer" 
                        className="form-control p-lg-2" 
                        /> */}
                    </div>
                </div>
            </div>


            <div className='row pt-4' style={{height:'40vh' , overflowY:'auto'}}>
                <div className='col-sm-12 align-content-right'>
                    <div className="form-outline" style={{width: "100%"   }}>
                        {/* <label className="form-label m-0 font-bold" title='Describe the requirements and skills needed for the job' 
                        htmlFor="qualifications">Qualifications</label> */}
                        <VacancyEditor id="qualifications" title='Qualifications'
                         help ='Describe the requirements and skills needed for the job'
                         updateme={onUpdateQualifications}></VacancyEditor>

                        {/* <input    type="file" id="fileEmployeer" 
                        className="form-control p-lg-2" 
                        /> */}
                    </div>
                </div>
            </div>


            <div className='row pt-4' style={{height:'40vh', overflowY:'auto'}}>
                <div className='col-sm-12 align-content-right'>
                    <div className="form-outline" style={{width: "100%"   }}>
                        {/* <label className="form-label m-0 font-bold"  title='Insert additional information if needed'
                         htmlFor="aditionalinformation">Additional information</label> */}
                          <VacancyEditor id="asitionalinformation" title='Additional information' 
                            help='Insert additional information if needed'
                            updateme={onUpdateAdditional}></VacancyEditor>

                        {/* <input    type="file" id="fileEmployeer" 
                        className="form-control p-lg-2" 
                        /> */}
                    </div>
                </div>
            </div>



        <div className='row mt-6'>
            <div className='col-sm-6'>
                <div className='form-group'>

                    <label htmlFor="industry">Industry</label>
                    
                        <Select 
                        // defaultValue={selectedOptions}
                        onChange={setOptionIndustry}
                        // onInputChange={onInputChangeHandler}
                        options={options}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        
                        id="industry"
                        />

                </div>

            </div>




            <div className='col-sm-6 align-content-right'>
                <div className='form-group'>

                    <label htmlFor="searchType">Search Type</label>

                    <Select 
                        // defaultValue={selectedOptions}
                        onChange={setSearchType}
                        // onInputChange={onInputChangeHandler}
                        options={options2}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        
                        id="searchType"
                        />
                    

                </div>
            </div>
        </div>

        <div className='row'>

            <div className='col-sm-3 '>
                <div className='form-group'>

                    <label htmlFor="contractTypes">Contract Type</label>

                    <Select 
                        // defaultValue={selectedOptions}
                        onChange={setContractType}
                        // onInputChange={onInputChangeHandler}
                        options={options3}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        
                        id="contractTypes"
                        />
                    

                </div>
            </div>


            <div className='col-sm-3 '>
                <div className='form-group'>

                    <label htmlFor="yearsExperience">Years of Experience</label>

                    <Select 
                        // defaultValue={selectedOptions}
                        onChange={setYearsExperience}
                        // onInputChange={onInputChangeHandler}
                        options={options4}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        
                        id="yearsExperience" />
                </div>
            </div>

            <div className='col-sm-3 align-content-right'>
                <div className='form-group'>

                    <label htmlFor="rangeSalary">Range Salary</label>

                    <Select 
                        // defaultValue={selectedOptions}
                        onChange={setRangeSalary}
                        // onInputChange={onInputChangeHandler}
                        options={options5}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        
                        id="rangeSalary" />
                </div>
            </div>


            <div className='col-sm-3 '>
                <div className='form-group'>

                    <label htmlFor="replacementPeriod">Replacement Period</label>

                    <Select 
                        // defaultValue={selectedOptions}
                        onChange={setRepPeriod}
                        // onInputChange={onInputChangeHandler}
                        options={options6}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        
                        id="replacementPeriod"
                        />
                    

                </div>
            </div>


        </div>

        <div className='row'>

            <div className='col-sm-6 '>
                <div className="form-outline" style={{width: "100%"   }}>
                    <label className="form-label m-0" htmlFor="generalConditions">General Conditions</label>
                    <textarea class="form-control" id="generalConditions" rows="2"></textarea>
                </div>
            </div>

            <div className='col-sm-6 align-content-right'>
                <div className="form-outline" style={{width: "100%"   }}>
                    <label className="form-label m-0" htmlFor="warrantyEmployeer">Warranty Employeer</label>
                    <textarea class="form-control" id="warrantyEmployeer" rows="2"></textarea>
                </div>
            </div>



        </div>


        <div className='row'>

            <div className='col-sm-3 '>
                <div className="form-outline" style={{width: "100%"   }}>
                    <label className="form-label m-0" htmlFor="fee">Fee</label>
                    <input    type="text" id="fee" 
                    className="form-control p-lg-2" 
                    />
                </div>
            </div>

            <div className='col-sm-3 '>
                <div className="form-outline" style={{width: "100%"   }}>
                    <label className="form-label m-0" htmlFor="terms">Terms</label>
                    <input    type="text" id="terms" 
                    className="form-control p-lg-2" 
                    />
                </div>
            </div>


            <div className='col-sm-3 '>
                <div className='form-group'>

                    <label htmlFor="vacancyStatus">Vacancy Status</label>

                    <Select 
                        // defaultValue={selectedOptions}
                        onChange={setVacancyStatus}
                        // onInputChange={onInputChangeHandler}
                        options={options7}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        
                        id="vacancyStatus"
                        />
                    

                </div>
            </div>

        </div>



        <div className='row mt-3'>
                <div className='col-sm'>
                <div className='form-group'>

                    <label htmlFor="country">Country</label>

                    <select className="form-control w-40" id="country" 
                    type="select" maxLength={50} onChange={countryChange} ref={countryRef} value={nCountry}>

                    </select>

                </div>

                </div>
            

                <div className='col-sm'>
                    <div className='form-group'>

                        <label htmlFor="state">State</label>

                        <select className="form-control w-40" id="state" type="select" 
                        maxLength={50} onChange={stateChange} ref={stateRef} value={nState}>

                        </select>

                    </div>
                </div>
                

                <div className='col-sm'>
                <div className='form-group'>

                    <label htmlFor="city">City</label>

                    <select className="form-control w-40" id="city" type="select" maxLength={50} ref={cityRef}
                    value={nCity} onChange={cityChange}>

                    </select>

                </div>
                </div>
            </div>

        <div className='row'>

            <div className='col-sm-3 '>
                <div className='form-group'>

                    <label htmlFor="dueDate">Due Date</label>

                    <Select 
                        // defaultValue={selectedOptions}
                        onChange={setDueDate}
                        // onInputChange={onInputChangeHandler}
                        options={options8}
                        isMulti={false}
                        closeMenuOnSelect={false}
                        
                        id="dueDate"
                        />
                    

                </div>


            </div>

            <div className='col-sm-6 align-content-right'>
                    <div className="form-outline" style={{width: "100%"   }}>
                        <label className="form-label m-0" htmlFor="other">Other</label>
                        <textarea class="form-control" id="other" rows="2"></textarea>
                        
                    </div>
            </div>



            <div className='col-sm'>
                <label htmlFor="flexRadioDefault1">Pyment Installments</label>
                    <div className="form-check">
                        <input className="form-check-input radio" type="radio" 
                            name="flexRadioDefault" id="paymentInstallmentsYes" checked />
                        <label className="form-check-label" htmlFor="paymentInstallmentsYes">
                            Yes
                        </label>
                    </div>
                    <div className="form-check">
                        <input onChange={console.log("onChange")} className="form-check-input radio" type="radio" name="flexRadioDefault" id="paymentInstallmentsNo" />
                        <label className="form-check-label" htmlFor="paymentInstallmentsNo">
                            No
                        </label>
                    </div>

            </div>


        </div>

        <div className='row'>

            <div className='col-sm-6 '>
                <div className="form-outline" style={{width: "100%"  }}>
                    <label className="form-label m-0" htmlFor="targetCompanies">Target Companies</label>
                    <input    type="text" id="targetCompanies" 
                    className="form-control p-lg-2" 
                    />
                </div>


            </div>

            <div className='col-sm-6 align-content-right'>
                <div className="form-outline" style={{width: "100%"   }}>
                    <label className="form-label m-0" htmlFor="offLineCompanies">Off Line Companies</label>
                    <input    type="text" id="offLineCompanies" 
                    className="form-control p-lg-2" 
                    />
                </div>


            </div>


        </div>


        <div className='row'>

            <div className='col-8'>
                <div className="form-group" style={{width: "100%"  }} >
                    <label htmlFor="generalCondition">General Condition</label>
                    <textarea  className="form-control form-control-sm" id="generalCondition"  rows="3" />
                    
                </div>
            </div>

            <div className='col-sm-4 align-content-right'>
                <label htmlFor="flexRadioDefault1">Approved Description</label>
                    <div className="form-check">
                        <input className="form-check-input radio" checked type="radio" name="flexRadioDefault2" id="approvedDesciption1"/>
                        <label className="form-check-label "  htmlFor="approvedDesciption1">
                            Confirmation Pending
                        </label>
                    </div>
                    <div className="form-check">
                        <input onChange={console.log("onChange")} className="form-check-input radio " type="radio" name="flexRadioDefault2" id="approvedDesciption2" />
                        <label className="form-check-label" htmlFor="approvedDesciption2">
                            Approved
                        </label>
                    </div>

                    <div className="form-check">
                        <input onChange={console.log("onChange")} className="form-check-input" type="radio" name="flexRadioDefault2" id="approvedDesciption3" />
                        <label className="form-check-label" htmlFor="approvedDesciption3">
                            Not Approved
                        </label>
                    </div>


            </div>


        </div>

        <div className='row align-content-center'>


            <div className='col-sm-4'>
                <button type="submit" className="btn btn-primary mt-3 button-3"  >Submit</button> 
            </div>

            <div className='col-sm-4'>
                
            </div>

            <div className='col-sm-4'>
                <button type="button" className="btn btn-primary mt-3 button-3"  >Back</button> 
            </div>



        </div>







        </Form>


    </div>
    </div>
  )
}
