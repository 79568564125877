import React from 'react'

import { Row , Col } from 'react-bootstrap'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'



const listleft = [

    ["4" , "1" , "3"],
    ["3" , "0" , "2"],
    ["5" , "2" , "4"],
    ["2" , "1" , "1"],
    ["3" , "2" , "2"],
    ["1" , "0" , "0"]

  ]

  const listRight = [

    ["6" , "3" , "12"],
    ["5" , "0" , "4"],
    ["8" , "2" , "10"],
    ["5" , "0" , "8"],
    ["6" , "2" , "12"],
    ["3" , "1" , "5"]

  ]


export default function NewProyectsDetails(props) {
  return (
    <div className='box-7 p-0 pt-2  ' style={{height:'100px'}}>

        <div style={{  width : '100%' , verticalAlign:'top'}}>



            <Row className='ps-2' style={{height:'25px'}}>


                <Col className='col-2'>
                    <FontAwesomeIcon   icon={faCircle} color='green'  ></FontAwesomeIcon>
                </Col>

                <Col className='col-8'>
                    <p className='body-4'> Suppliers</p>
                </Col>

                <Col className='col-1'>
                    <p>{listleft[props.fee][0]}</p>
                </Col>
                
            </Row>


            <Row className='ps-2' style={{height:'25px'}}>


                <Col className='col-2'>
                    <FontAwesomeIcon   icon={faCircle} color='red'  ></FontAwesomeIcon>
                </Col>

                <Col className='col-8'>
                    <p className='body-4'>Request</p>
                </Col>

                <Col className='col-1'>
                    <p>{listleft[props.fee][1]}</p>
                </Col>
                
            </Row>


            <Row className='ps-2' style={{height:'25px'}}>

                <Col className='col-2'>
                    <FontAwesomeIcon   icon={faCircle} color='blue'  ></FontAwesomeIcon>
                </Col>

                <Col className='col-8'>
                    <p className='body-4'>Views</p>
                </Col>

                <Col className='col-1'>
                    <p>{listleft[props.fee][2]}</p>
                </Col>
                
            </Row>



        </div>


        
    </div>
  )
}
