import React ,{useState , useEffect} from 'react'
import { Container , Row , Col } from 'react-bootstrap'

import Left2 from '../DashAdmin/Left2' // D:\hunterhire\nexjjs\hunterhire\pages\Dashboard\AdminPanel\DashAdmin\Left2.js

import Top from './Top'

import SuperCenter from './SuperCenter'

import UserTable from './Left2Menu/UserTable'

import CompaniesTable from './Left2Menu/CompaniesTable'

import InvoicesTable from './Left2Menu/InvoicesTable'

import MessagesTable from './Left2Menu/MessagesTable'

import UserAdd from './Plus/UserAdd'



//import UserEmplAdd from './Plus/UserEmplAdd'

import PostVacancy from '../../../entry/PostVacancy'


import CompanyAdd from '../DashAdmin/Plus/CompanyAdd'

// D:\hunterhire\nexjjs\huntelfull\hunterhire\src\Dashboard\AdminPanel\DashAdmin\Plus\CompanyAdd.js


import CategoriesTable from './Left2Menu/CategoriesTable'

import VacancyCarrousel from './Vacancies/VacancyCarrousel'

import VacancyAdd from './Vacancies/VacancyAdd'


export default function DashAdmin( { user }) {

  const [tableName , setTableName] = useState( "none")

  useEffect(()=>{
    document.title = "Hunter&Hire"
  })

  function centerTable( nameTable )
  {

    console.log( "centerTable = " + nameTable ) ;
    setTableName( nameTable )

  }

  let table ;

  function getTable()
  {

    switch(tableName) {
      case "UserTable":
        table = <UserTable showHeader={true} closeTable={closeTable}></UserTable> 
        break;

      case "CompaniesTable":
        table = <CompaniesTable showHeader={true} closeTable={closeTable}></CompaniesTable> 
        break;

      case "InvoicesTable":
        table = <InvoicesTable showHeader={true} closeTable={closeTable}></InvoicesTable> 
        break;


      case "MessagesTable":
        table = <MessagesTable showHeader={true} closeTable={closeTable}></MessagesTable> 
        break;

      case "UserAdd":
        table = <UserAdd  closeTable={closeTable}></UserAdd> 
        break;


      case "UserEmpl":

        table = <PostVacancy  closeTable={closeTable}></PostVacancy> 

        break;

      case "CompanyAdd":

        table = <CompanyAdd  closeTable={closeTable}></CompanyAdd> 

        break;


      case "Categories":

        table = <CategoriesTable  closeTable={closeTable}></CategoriesTable> 

        break;


      case "Vacancy":

        table = <VacancyCarrousel  closeTable={closeTable}></VacancyCarrousel> 

        break;
    
        case "VacancyAdd":
  
          table = <VacancyAdd  closeTable={closeTable}></VacancyAdd> 
  
          break;
    

      default:
        table =   ( <> 
          <SuperCenter  ></SuperCenter>  </> ) 
        }
     

    return table ;
  }
  function closeTable()
  {
    setTableName( "none" ) ;
  }



  return (
    <div className='m-0 small bg-right'> 
      <div className='dash-grid'>
        <header className='dash-header' >

          <Top centerTable={centerTable} user={user} ></Top>

        </header>
        <section className='dash-left' style={{display:'inline-block'}} >
          <Left2 centerTable={centerTable}></Left2>
        </section>
        
        <main className='dash-main'>
        { getTable() }
        </main>
        

      </div>
    </div>
  )
}
