import React , { useEffect , useState } from 'react'

import Modal from 'react-modal'

import { Row , Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faMessage , faXmark } from '@fortawesome/free-solid-svg-icons'

import { Tooltip } from '@mui/material'






export default function MessagesMenu( { showText , centerTable }) {

  const [theOpen,setTheOpen] = useState( false ) ;


  function setCenterTable(  )
  {
      centerTable( "MessagesTable") ; // MessagesTable
  }


  return (
    <div  >

      
        <div className=' drop-item' onClick={setCenterTable}>
          <Tooltip title="Messages" placement='right' arrow>
            <FontAwesomeIcon  icon={faMessage}  style={{color:'white' , height:25}}/>
          </Tooltip>
        </div>
        

        {/* <Modal style={{background:'blue'}} isOpen={theOpen} contentLabel='Prueba de Modal'>

          <Row>
            <Col className='col-2'>
              
              <FontAwesomeIcon onClick={setClose} icon={faXmark} title='Close Messages' style={{color:'blue' , height:25 , display:'inline'}}/>

            </Col>

            <Col className='col-3'>
            </Col>

            <Col>
              <h3>Messages</h3>
            </Col>

          </Row>

          <MessagesTable></MessagesTable>

        </Modal> */}
   
    </div>
  )
}
