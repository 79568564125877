import React , {useEffect} from 'react'
import { Container , Row , Col } from 'react-bootstrap'

import Left2 from './Left2'

import Top from './Top'

import SuperCenter from './SuperCenter'

export default function Dashboard2 ({user='none'}) {

  

  useEffect(() => {

    document.title = "Hunter&Hire" ;

  } , [])

  return (

    <div className='m-0 small bg-right'> 
    <div className='dash-grid'>
      <header className='dash-header' >

        <Top name={user}></Top>

      </header>
      <section className='dash-left' style={{display:'inline-block'}} >
        <Left2></Left2>
      </section>
      
      <main className='dash-main'>
      <SuperCenter  ></SuperCenter> 
      </main>
      

    </div>
  </div>



    // <div className='m-0 small bg-right' style={ { width:'100%' , overflow:'hidden' }} > 
        

    //   <div  >

    //     <div className='dash-left' style={{display:'inline-block'}} >
    //       <Left2></Left2>
    //     </div>
          
    //     <div className='ms-1 mt-0 pt-0 dash-vertical' style={{display:'inline-block' , width :'calc(100% - 200px)'}} >
    //       <Top ></Top>
    //       <SuperCenter  ></SuperCenter> 
    //     </div>


    //   </div>
      

          
          

    // </div>
  )
}
