import React from 'react'

import { Image } from 'react-bootstrap'


export default function RightNewaItem(props) {
  return (

    <div className='w100 mb-4 news-item' style={{height:'60px'}}>

    <div style={{display:'inline-block'}}>

    <Image src='/user-perfil-icono.svg' size='30px' alt='' style={{width:'30px'}}></Image>

    </div>

    <div className='ps-2' style={{display:'inline-block'}}>
      <div>
        <div>
          <p style={{height:12}}>Hire User</p>
        </div>
        <div style={{height:12}}>
          <p>{props.message}</p>
        </div>
      </div>

      
    </div>

      {/* <p>hola amigo</p> */}

    <hr></hr>

  </div>

    // <div className='w100 mb-4 news-item' style={{height:'60px'}}>

    //   <div style={{display:'inline-block'}}>

    //   <Image src='/user-perfil-icono.svg' size='30px' alt='' style={{width:'30px'}}></Image>

    //   </div>

    //   <div className='ps-2' style={{display:'inline-block'}}>
    //     <div>
    //       <div>
    //         <p style={{height:12}}>Hire User</p>
    //       </div>
    //       <div style={{height:12}}>
    //         <p>{props.message}</p>
    //       </div>
    //     </div>
 
        
    //   </div>

 

    //   <hr></hr>

    // </div>
  )
}
