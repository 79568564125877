import React from 'react'


import { Row , Col, Button} from 'react-bootstrap'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLarge , faClock, faEnvelope, faClockFour, faPhone } from '@fortawesome/free-solid-svg-icons'

export default function NewRegItem({name,lastname,position,
    eltime,email="" , phone=""}) {
  return (
    <div >
        <Row className='mt-2 reg-item'>
            <Col className='col-1'>
                <FontAwesomeIcon color='#A5A5A5' icon={faUserLarge}></FontAwesomeIcon>
            </Col>

            <Col className='col-8'>

                <div className=''>
                    <p className='body-2 font-bold' >{name}{" "}{lastname}</p>
                    <p className='body-5 font-bold tx-color-gray-dark text-uppercase' >{position}</p>                    
                </div>               

            </Col>

            <Col className='col-3'>          
                <FontAwesomeIcon color='#A5A5A5' icon={faClockFour} style={{display:'inline-block'}}></FontAwesomeIcon>
                <p className='tx-color-gray-dark' style={{display:'inline-block'}}>&nbsp;{eltime}</p>

            </Col>
        </Row>
        <Row className='d-flex aling-items-center gap-3'>
                    <Col className='d-flex aling-items-center'>
                        <FontAwesomeIcon color='#7507FA' icon={faEnvelope} ></FontAwesomeIcon><p className='body-5'>&nbsp;{email}</p>
                    </Col>
                    <Col className='d-flex aling-items-center'>
                        <FontAwesomeIcon color='#7507FA' icon={faPhone} ></FontAwesomeIcon><p className='body-5'>&nbsp;{phone}</p>
                    </Col>                    
                </Row>
        <hr></hr>
    </div>
  )
}
