import React , { useEffect , useState } from 'react'



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUserGroup  } from '@fortawesome/free-solid-svg-icons'


import { Tooltip } from '@mui/material';





export default function CompaniesMenu( {  centerTable}) {

  const [theOpen,setTheOpen] = useState( false ) ;


  function setCenterTable(  )
  {
      centerTable( "CompaniesTable") ;
  }



  return (
    <div  >

      
        <div className=' drop-item' onClick={setCenterTable}>
          <Tooltip title="companies" placement='right' arrow>
            <FontAwesomeIcon  icon={faUserGroup}  style={{color:'white' , height:25}}/>
          </Tooltip>

        </div>
        

   
    </div>
  )
}
