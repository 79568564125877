
import React, { useState, useEffect } from 'react'

import Carousel from 'react-grid-carousel'

//import { Image } from 'react-bootstrap'


import VacancyCard2 from './Vacancies/VacancyCard2'

import axios from 'axios'

import getGlobal from '../../../setglobal'


export default function Carousel01 ({getData}) {


  const [screenSize, setScreenSize] = useState(3);
  const [cards,setcards] = useState([]) ;


  

  function getCurrentDimension(){

    console.log( window.innerWidth ) ;

    if ( window.innerWidth > 1365 )
    {
      return 3 ;
    }

    return 1 ;



  }

  function getVacancy()
  {

    //axios.get( "http://localhost:3000/api/Data/getvacancycard/?limit=10")
    axios.get( getGlobal() + "getvacancycard/?limit=10")
    .then( ( response ) =>{

      //console.log( "getvacancycard length = " + JSON.stringify( response.data ) ) ;

      setcards( response.data ) ;

      getData( response.data ) ;


    })
    .catch( e => {

      console.log( e ) ;

    }

      

    ).finally(() =>{

    })


  }

  useEffect(() =>{

    getVacancy() ;

  },[])

  useEffect(() => {
      const updateDimension = () => {
          setScreenSize(getCurrentDimension())
      }
      window.addEventListener('resize', updateDimension);
  
  
      return(() => {
          window.removeEventListener('resize', updateDimension);
      })
  }, [screenSize])


  function getItems(size)
  {

    if( cards.length < 1 )
    {
      return ;
    }

    var arr = [] ;
    for( var i = 0 ; i < 10 ; i++){

      function getItem(i)
      {
        return( <Carousel.Item>
          <VacancyCard2 index={i+1} name={cards[i].name.substring(0,40)} 
            company={cards[i].company} status={cards[i].status}
            username={cards[i].username}
            responsabilities={cards[i].responsabilities.substring(0,120)} ></VacancyCard2>
          </Carousel.Item>
      )

      }
      arr.push( getItem(i) ) ;
    }

    return arr ;

  }

// Se tenia scrren size
  return (
    <Carousel  cols={screenSize} rows={1} gap={16} loop>
      { getItems() }
    </Carousel>
  )
}