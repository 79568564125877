import React from 'react'

import { Row , Col, Carousel } from 'react-bootstrap'

import Carousel01 from './Carousel01'

import NewProyectsUpRight from './NewProyectsUpRight'


export default function NewProyects() {
  return (

  <div>
    <div className='d-flex justify-content-between'>

        <div className='d-flex gap-2'>
          <h2 className='h2 font-bold'>New Proyects</h2>
          <div className='circle-not'>10</div>
        </div>

        <div className='col-4'>

          <NewProyectsUpRight></NewProyectsUpRight>
                
        </div>

    </div>

        <div className='pt-2'>
            <Carousel01></Carousel01>
        </div>
    </div>
  )
}
