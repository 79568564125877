import React from 'react'

import { Row , Col } from 'react-bootstrap'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen , faCircleUser , faClock , faCircleQuestion , faCircleCheck , faCircleXmark } from '@fortawesome/free-solid-svg-icons'

import NewProyectsDetails from './NewProyectsDetails'

const feeRange = [
    ["28" , "85" , "105"],
    ["22" , "60" , "75"],
    ["20" , "70" , "85"],
    ["18" , "90" , "105"],
    ["24" , "65" , "95"],
    ["27" , "58" , "75"]
  ]

  const listleft = [

    ["4" , "1" , "3"],
    ["3" , "0" , "2"],
    ["5" , "2" , "4"],
    ["2" , "1" , "1"],
    ["3" , "2" , "2"],
    ["1" , "0" , "0"]

  ]

  const listRight = [

    ["6" , "3" , "12"],
    ["5" , "0" , "4"],
    ["8" , "2" , "10"],
    ["5" , "0" , "8"],
    ["6" , "2" , "12"],
    ["3" , "1" , "5"]

  ]

  const listPosted = [
    " 3 days ago " ,
    " 1 week ago " ,
    " yesterday " ,
    " today " ,
    " one Month ago " ,
    " 6 hours ago "
  ]

  
  


export default function NewProyectsItem( props ) {
  return (
    <div className='box-4' style={{width:'100%' , height:'255px'}}>

        <div className='d-flex justify-content-between'>
            <div>
                    <p className='body-3 font-bold'>{props.sector}</p>
                </div>
                <div className='circle-icon'>
                        <FontAwesomeIcon icon={faPen} title='Vacancy' style={{height:10}}/>
                    </div>
                              
            </div>   

            <div>
                    <p className='font-bold status-green' >{"Open"}</p>
            </div>            


            <div className='d-flex flex-column gap-1'>
                <div className='d-flex gap-2'>
                    <FontAwesomeIcon  icon={faClock} title='Vacancy' style={{color:'#A5A5A5' , height:'15px' }}/>
                    <p className='body-5'>10 min ago</p>
                </div>
                <div className='d-flex gap-2'>
                    <FontAwesomeIcon  icon={faCircleUser} title='Vacancy' style={{color:'#A5A5A5' , height:'15px' }}/>            
                    <p className='body-5'>Posted by {"Adriana Castillo"}</p>
                </div>
            </div>         


        <Row style={{height:'20px'}}>

 
            <Col className='col-9'>
                <p className=' body-4 tx-color-gray-dark'>Anual Salary: {feeRange[props.fee][1]},000 - {feeRange[props.fee][2]},000</p>
            </Col>


            
        </Row>

        {/* <Row>
            <Col className='col-1'></Col>


            <Col className='col-11'>
                <div className=' text-center' style={{ height :'22px' , border : '1px solid #707070' ,  borderRadius:'15px'}}>
                    <p>{props.industry}</p>

                </div>
                
            </Col>
            
        </Row>


        <Row className='mt-2'>
            <Col className='col-1'></Col>
            <Col>
                <p className='body-3 font-regular font-bold'>Fee : 
                    <span style={{fontWeight:'bold'}}>{feeRange[props.fee][0]}%</span>  of {feeRange[props.fee][1]},000 - {feeRange[props.fee][2]},000</p>
            </Col>
        </Row> */}
 

        <Row style={{height:'110px'}}>
            <NewProyectsDetails fee={props.fee}></NewProyectsDetails>

            <div className=' align-content-center mt-0 mb-0'>

            <Row className='mt-0'>
                <Col className='col-4'>
                </Col>

                <Col className='col-2'>

                    <FontAwesomeIcon icon={faCircleCheck} color='green' ></FontAwesomeIcon>
                    
                </Col>

                <Col className='col-2' >
                    <FontAwesomeIcon icon={faCircleXmark} color='pink'></FontAwesomeIcon>
                </Col>

                <Col className='col-4'>
                </Col>
            </Row>
            </div>
        </Row>
    </div>
  )
}

