import React from 'react'

import { Container , Row , Image} from 'react-bootstrap'

import { MdOutlinePeople } from "react-icons/md";

import { IoPeople } from "react-icons/io5";



import { AiOutlineFolderOpen , AiFillBell, AiFillFolderOpen } from "react-icons/ai";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen , faCheckToSlot , faMessage , faBell , faCircleUser , faCircleDollarToSlot , faGear } from '@fortawesome/free-solid-svg-icons'


import UserMenu from './Left2Menu/UserMenu';
import CompaniesMenu from './Left2Menu/CompaniesMenu';
import InvoicesMenu from '../DashAdmin/Left2Menu/InvoicesMenu'
import MessagesMenu from '../DashAdmin/Left2Menu/MessagesMenu';

import CategoriesMenu from '../DashAdmin/Left2Menu/CategoriesMenu'

import VacancyMenu from './Plus/VacancyMenu';

import { Tooltip } from '@mui/material';


export default function Left2( {centerTable}) {
  return (
    <Container className='p-0 m-0' >
        <div className="d-flex flex-column">
          <div className='d-flex justify-content-center py-3'>
            <Image  src='/logo-hunter-hire-icono.svg' alt='logo-hunter' width={'45px'}></Image>
          </div>

          <div className='py-5'>
            <div className='d-flex justify-content-center'>
                <UserMenu centerTable={centerTable}></UserMenu>
              
              {/* <FontAwesomeIcon icon={faCircleUser} title='Users' style={{color:'white' , height:25}}/> */}
            </div>

            <div className='d-flex justify-content-center py-2'>
              {/* <FontAwesomeIcon icon={faFolderOpen} title='Vacancies' style={{color:'white' , height:25}}/> */}
              <VacancyMenu centerTable={centerTable}></VacancyMenu>
            </div>

            <div className='d-flex justify-content-center py-2'>
              <Tooltip title="Notifications" placement='right' arrow>
                <FontAwesomeIcon icon={faBell} style={{color:'white' , height:25}}/>
              </Tooltip>
            </div>

            <div className='d-flex justify-content-center py-2'>
              {/* <FontAwesomeIcon icon={faUserGroup} title='Companies' style={{color:'white' , height:25}}/> */}
              <CompaniesMenu centerTable={centerTable}></CompaniesMenu>
            </div>

            
            <div className='d-flex justify-content-center py-2'>
              {/* <FontAwesomeIcon icon={faCircleDollarToSlot} title='Invoices' style={{color:'white' , height:25}}/> */}
              <InvoicesMenu centerTable={centerTable} ></InvoicesMenu>
            </div>

            <div className='d-flex justify-content-center py-2'>
              {/* <FontAwesomeIcon icon={faMessage} title='Messages' style={{color:'white' , height:25}}/> */}
              <MessagesMenu centerTable={centerTable} ></MessagesMenu>
            </div> 

            <div className='d-flex justify-content-center py-2'>
              {/* <FontAwesomeIcon icon={faMessage} title='Messages' style={{color:'white' , height:25}}/> */}
              <CategoriesMenu centerTable={centerTable} ></CategoriesMenu>
            </div> 

            <div className='d-flex justify-content-center py-2'>
              <Tooltip title="Logs" placement='right' arrow>
                <FontAwesomeIcon icon={faCheckToSlot}  style={{color:'white' , height:25}}/>
              </Tooltip>

            </div>

            <div className='d-flex justify-content-center py-2'>
              <Tooltip title="Configuration" placement='right' arrow>
                <FontAwesomeIcon icon={faGear}  style={{color:'white' , height:25}}/>
              </Tooltip>

            </div>
          </div>        
        </div>
    </Container>
  )
}
