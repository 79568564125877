// Los botones de arriba a la derecha de los "new proyects"
import React , { useState  }from 'react'

import { Row , Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons'

export default function NewProyectsUpRight({change}) {

    const [selValue,setSelValue] = useState("1")


    const onValueChage= (val) => {


        var myVal = document.getElementById( 'selected').value ;

        console.log( "onValueChange = " +  myVal ) ;

        if ( selValue !== myVal )
        {
            console.log( "Cambio el valor setValue = " + selValue + " myVal =  " + myVal ) ;
            setSelValue( myVal ) ;

            if ( myVal === "1" || myVal === "2" )
            {
                if ( myVal === "1")
                {
                    console.log( "show carousel") ;
                    change("showcarousel") ;
                    return ;
                }
                console.log( "show grid") ;
                change("showgrid") ;

            }
        }



    }
  return (
    <div>

        <div className='d-flex gap-2'>
            <div>        
                <button className='button-3'>
                    <FontAwesomeIcon icon={faArrowRotateLeft} color='white'></FontAwesomeIcon>&nbsp;Undo

                </button>
            </div>
            {/* <div>
                <div className='d-flex justify-content-center'>
                   

                    <select onChange={onValueChage} id="selected" className="form-select" aria-label="Default select example">
                        
                        <option value="1">Carousel</option>
                        <option value="2">List</option>
                        <option value="3">Most Recent</option>
                    </select>
                </div>
            </div> */}

        </div>


    </div>
  )
}
