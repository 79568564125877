import React from 'react'


import { Row , Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'


export default function ProyectStatus() {
  return (
    <div className='d-flex flex-column h-100'>

        <div>
            <h2 className='h2 font-bold'>Platform Status</h2>
        </div>

        <div className='mt-4'>
        
            <div > 
                <div className='d-flex justify-content-between'> 
                    
                    <p className='body-3 circle-green'>Open Vacancies</p>
                    <p className='body-3'>10 </p>
                </div>      
                
            </div>

            <hr></hr>

            <div > 
                <div className='d-flex justify-content-between'> 
                    
                    <p className='body-3 circle-orange'>Close Vacancies</p>
                    <p className='body-3'>10 </p>
                </div>      
                
            </div>

            <hr></hr>   

            <div > 
                <div className='d-flex justify-content-between'> 
                    
                    <p className='body-3 circle-pink'>Invoiced</p>
                    <p className='body-3'>10 </p>
                </div>      
                
            </div>

            <hr></hr>   

            <div > 
                <div className='d-flex justify-content-between'> 
                    
                    <p className='body-3 circle-purple'>Pipeline</p>
                    <p className='body-3'>10 </p>
                </div>      
                
            </div>

        </div>



    </div>
  )
}
