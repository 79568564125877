
import React, { useState, useEffect } from 'react'

import Carousel from 'react-grid-carousel'

//import { Image } from 'react-bootstrap'

import NewProyectsItem from './NewProyectsItem'

const feeRange = [
  ["28" , "85" , "105"],
  ["22" , "60" , "75"],
  ["20" , "70" , "85"],
  ["18" , "90" , "105"],
  ["24" , "65" , "95"],
  ["27" , "58" , "75"]
]

export default function Carousel01 () {


  const [screenSize, setScreenSize] = useState(3);

  

  function getCurrentDimension(){
    // return {
    //     width: window.innerWidth,
    //     height: window.innerHeight
    // }

    console.log( window.innerWidth ) ;

    if ( window.innerWidth > 1450 )
    {
      return 3 ;
    }

    return 2 ;



  }

  useEffect(() => {
      const updateDimension = () => {
          setScreenSize(getCurrentDimension())
      }
      window.addEventListener('resize', updateDimension);
  
  
      return(() => {
          window.removeEventListener('resize', updateDimension);
      })
  }, [screenSize])


  return (
    <Carousel cols={3} rows={1} gap={20} loop>
      <Carousel.Item>
        <NewProyectsItem industry="Information Technology and Services"
          sector="PROGRAM DEVELOPMENT" fee={0}></NewProyectsItem>
      </Carousel.Item>
      <Carousel.Item>
        <NewProyectsItem industry="International Trade and Development"
        sector="CONSULTANT" fee={1}> </NewProyectsItem>
      </Carousel.Item>
      <Carousel.Item>
        <NewProyectsItem industry="Leisure, Travel & Tourism" sector="MANAGER" fee={2}></NewProyectsItem>
      </Carousel.Item>
      <Carousel.Item>
        <NewProyectsItem industry="Human Resources" sector="HUNTER" fee={3}></NewProyectsItem>
      </Carousel.Item>
      <Carousel.Item>
        <NewProyectsItem industry="Graphic Design" sector="DESIGNER" fee={4}></NewProyectsItem>
      </Carousel.Item>
      <Carousel.Item>
        <NewProyectsItem industry="Cosmetics" sector="SALES" fee={5}></NewProyectsItem>
      </Carousel.Item>

      {/* <Carousel.Item>
        <Image alt='' width="100%" src="https://picsum.photos/800/600?random=1" />
      </Carousel.Item>
      <Carousel.Item>
        <Image alt='' width="100%" src="https://picsum.photos/800/600?random=2" />
        
      </Carousel.Item>
      <Carousel.Item>
        <Image alt='' width="100%" src="https://picsum.photos/800/600?random=3" />
        
      </Carousel.Item> */}
    </Carousel>
  )
}