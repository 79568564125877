import React from 'react'

import { Container , Row , Image} from 'react-bootstrap'

import { MdOutlinePeople } from "react-icons/md";

import { IoPeople } from "react-icons/io5";



import { AiOutlineFolderOpen , AiFillBell, AiFillFolderOpen } from "react-icons/ai";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen , faUser , faUserGroup , faBell  } from '@fortawesome/free-solid-svg-icons'

import { Tooltip } from '@mui/material';


// AiOutlineFolderOpen

export default function Left2() {
  return (


    <Container className='p-0 m-0' >

        <div className="d-flex flex-column">
          <div className='d-flex justify-content-center py-3'>
            <Image  src='/logo-hunter-hire-icono.svg' alt='logo-hunter' width={'45px'}></Image>
          </div>          
        
          <div className='py-5'>
            <div className='d-flex justify-content-center'>
              {/* <AiOutlineFolderOpen data-toggle="tooltip" title='Users' className='' size={35}/> */}
              {/* <faFolderOpen title='Users' size={35}></faFolderOpen> */}
              <Tooltip title="Proyects" placement='right' arrow>
                <FontAwesomeIcon icon={faFolderOpen} title='Proyects' style={{color:'white' , height:25}}/>
              </Tooltip>
            </div>
          
            <div className='d-flex justify-content-center py-2'>
              {/* <MdOutlinePeople data-toggle="tooltip" title='Candidates' className='' size={35}/>  */}
              <Tooltip title="Candidates" placement='right' arrow>
                <FontAwesomeIcon icon={faUser} title='Candidates' style={{color:'white' , height:25}}/>
              </Tooltip>
            </div>

            <div className='d-flex justify-content-center py-2'>
              {/* <IoPeople data-toggle="tooltip" title='Candidates' className='' size={35}/> */}
              <Tooltip title="Team" placement='right' arrow>
                <FontAwesomeIcon icon={faUserGroup} title='Team' style={{color:'white' , height:25}}/>
              </Tooltip>
            </div>

            <div className='d-flex justify-content-center py-2'>
              {/* <AiFillBell data-toggle="tooltip" title='Alerts' className='' size={35}/> */}
              <Tooltip title="Messages" placement='right' arrow>
                <FontAwesomeIcon icon={faBell} title='Messages' style={{color:'white' , height:25}}/>
              </Tooltip>
            </div>
          </div>        
        </div>
    </Container>

  )
}
