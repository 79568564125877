import React from 'react'

import { Row , Col } from 'react-bootstrap'

import SuperGoodJob from './SuperComponents/SuperGoodJob'
import SuperGoogStats from './SuperComponents/SuperGoogStats'

export default function SuperCenterTopLeft() {
  return (
    <div>



    <div>

      <div className=''>
          <h2 className='h2 font-bold'>Performance</h2>
      </div>

      <div className='d-flex gap-3'>

          <div>
              <div>
                  <SuperGoodJob></SuperGoodJob>
              </div>
          </div>

          <div >
              <div>
                  <SuperGoogStats></SuperGoogStats>
              </div>                
          </div>         

      </div>    
      </div>
     </div>    




   
  )
}
