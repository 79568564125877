import React  from 'react'


import { Tooltip } from '@mui/material';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faUsersRectangle } from '@fortawesome/free-solid-svg-icons'


export default function CorpCandidates( { showText , centerTable } ) {


  function setCenterTable(  )
  {
      centerTable( "Candidates") ;
  }


  return (
    <div>
        <div className='drop-item' onClick={setCenterTable}>
        <Tooltip title="Candidates" placement='right' arrow>
            <FontAwesomeIcon  icon={faUsersRectangle}  
              style={{color:'white' , height:25 , display:'inline'}}/>
          </Tooltip>
          { showText  &&    (  <p style={{display:'inline'}}>  &nbsp; &nbsp;Candidates</p> ) }
        </div>        
    </div>
  )
}
