import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

export default function CorpSuperStats() {
  return (
    <div className='body-0 mt-2 tx-color-cyan font-bold text-center'>
        <p style={{display:'inline-block'}}>4.5</p> 
        <div className='pb-2' style={{display:'inline-block'}}>
          <FontAwesomeIcon icon={faStar} style={{color:'purple' , height:12}}/>
        </div>
        
        <p className='body-4 font-regular tx-color-black'>Good Job!</p>
    </div>
  )
}
