import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
    {
      name: 'Nov',
      closed: 400,
      accepted: 240,
      undefined: 240,
    },
    {
      name: 'Dic',
      closed: 300,
      accepted: 139,
      undefined: 221,
    },
    {
      name: 'Jan',
      closed: 200,
      accepted: 980,
      undefined: 229,
    },
    {
      name: 'Feb',
      closed: 278,
      accepted: 390,
      undefined: 200,
    },
    {
      name: 'Mar',
      closed: 189,
      accepted: 480,
      undefined: 218,
    },
    {
      name: 'Apr',
      closed: 239,
      accepted: 380,
      undefined: 250,
    },
    {
      name: 'May',
      closed: 349,
      accepted: 430,
      undefined: 210,
    },
  ];
  
export default class ChatTopLeft extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/stacked-area-chart-ix341';

  render() {
    return (

      <ResponsiveContainer width="100%" height="95%" >

        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="closed" stackId="1" stroke="#8884d8" fill="#8884d8" />
          <Area type="monotone" dataKey="accepted" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
          <Area type="monotone" dataKey="undefined" stackId="1" stroke="#ffc658" fill="#ffc658" />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
