import axios from "axios";

import getGlobal from "./setglobal";


export default function CheckSession( session)
{
    console.log( "La peticion es = " + getGlobal() + 'getsession/?id=' + session ) ;

    console.log( "production = " + process.env.REACT_APP_production )

    var theGlobal = getGlobal() ;

    return new Promise( (resolve , reject ) => {

        
        console.log( "getGlobal = " + theGlobal ) ;

        axios.get( theGlobal + 'getsession/?id=' + session )
        .then( (response) => {

            console.log( "La respueste de getsession = " + response.data.value ) ;


            resolve( response.data ) ;
            return ;

        }) 
        .catch( (err) => {

            console.log( err ) ;

            resolve( false ) ;
            return ; 

        })

    })


}

