import React from 'react'


import { Row , Col, Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faPencilSquare , faClock , faCircleUser, faPen } from '@fortawesome/free-solid-svg-icons'

export default function VacancyCard2( {name,company,status,username  , responsabilities} ) {
  return (
    <div className=' box-4'>

        <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between'>
                <div>
                    <p className='body-2 font-bold'>{name}</p>
                </div>
                <div className='circle-icon'>
                        <FontAwesomeIcon icon={faPen} title='Vacancy' style={{height:10}}/>
                    </div>
                              
            </div>        
        
            <div className='d-flex flex-column gap-2'>
                <div>
                    <p className='body-5 tx-color-gray-dark text-uppercase font-bold'>{company}</p>
                </div>

                <div>
                    <p className='font-bold status-green' >{status}</p>
                </div>            
                
            </div>
            <div className='d-flex flex-column gap-1'>
                <div className='d-flex gap-2'>
                    <FontAwesomeIcon  icon={faClock} title='Vacancy' style={{color:'#A5A5A5' , height:'15px' }}/>
                    <p className='body-5'>10 min ago</p>
                </div>
                <div className='d-flex gap-2'>
                    <FontAwesomeIcon  icon={faCircleUser} title='Vacancy' style={{color:'#A5A5A5' , height:'15px' }}/>            
                    <p className='body-5'>Posted by {username}</p>
                </div>
            </div>                
          
        </div>

        <div className='mt-2'>
            <div>
                <div>
                    <p className='body-4'>Job Description</p>
                </div>
                <div>
                <p className='body-5'>{company} - {responsabilities}</p>
                </div>
            </div>
        </div>

    </div>
  )
}
