import React from 'react'

import { Container , Row , Col ,Image} from 'react-bootstrap'


import { MdOutlinePeopleOutline } from "react-icons/md";
import { HiPlus } from "react-icons/hi";


export default function TopRight() {

    const theClick = (e) => {
        console.log( "theClick") ;
    }

  return (


    <div>

        <div className='d-flex alig-items-center'>
            
            <div className=''>
                <div className="input-group" >
                    {/* <button type="button" className="btn btn-primary" style={{display:'inline-block'}}>
                        <MdOutlineSearch></MdOutlineSearch>
                    </button> */}
                    <div className="form-outline w-100 input-sm" style={{display:'inline-block'}}>
                        <input type="search" id="search001"  
                            className="form-control top-search button-3" 
                            size='sm' placeholder='Seach Oportunity' style={{color:'black' , display:'inline-block'}}/> 
                    
                    </div>
                </div>

            </div>
            <div className='d-flex align-items-center ps-3'>

                <div>                
                    <Image src='/action-icon.svg' size='30px' alt='' onClick={ theClick }
                      style={{width:'30px'}} ></Image>
                </div>                
            </div>
            
            <div className='d-flex align-items-center ps-3'>
                <div>
                <Image src='/user-perfil-icono.svg' size='30px' alt='' style={{width:'30px'}}></Image>
                        {/* <MdOutlinePeopleOutline size={40} className=" ps-0 pb-2 ps-2" style={{height:'60px' , display:'inline-block'}} ></MdOutlinePeopleOutline> */}
                </div>                
            </div>

        </div>

        
    </div>

    
  )
}
