import React from 'react'


import { Row , Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'


const data = [

    [
        { name: 'Accepted', value: 400 },
        { name: 'Pending', value: 300 },
        { name: 'Reject', value: 300 },
        //{ name: 'Group D', value: 200 },
      ],
      [
        { name: 'Accepted', value: 300 },
        { name: 'Pending', value: 300 },
        { name: 'Reject', value: 300 },
        //{ name: 'Group D', value: 300 },
      ],
      [
        { name: 'Accepted', value: 200 },
        { name: 'Pending', value: 100 },
        { name: 'Reject', value: 100 },
        //{ name: 'Group D', value: 50 },
      ]

]



export default function ChartAccountStats(props) {
  return (

    <div className=' ms-2'>

        <div>
            <div className='d-flex align-items-center'>
                <p className='body-3 circle-purple pe-2'>Accepted</p>
                {data[props.index][0].value}
            </div>
            <div className='d-flex align-items-center'>
                <p className='body-3 circle-pink pe-2'>Pending</p>
                {data[props.index][1].value}
            </div>
            <div className='d-flex align-items-center'>
                <p className='body-3 circle-cyan pe-2'>Reject</p>
                {data[props.index][2].value}
            </div>

        </div>                

    </div>

  )
}
