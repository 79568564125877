import React , { useState , useEffect} from 'react' 

import RightNewaItem from './RightNewaItem'
import axios from 'axios'

import getGlobal from '../../../setglobal'
//import { get } from '../../../../../hunterback'


export default function RightNews() {

  const [listItems , setListItems] = useState( [] )

  // Sergio larios id = 86
  function searchMessages()
  {

      var query = " sender_user_id = 86 " ; 

  
      //axios.post("http://localhost:3000/api/Data/getmessages" , {

      console.log( "searchItems") ;


      console.log( "getGlobal() = " + getGlobal() + 'getmessages') ;

      axios.post( getGlobal() + 'getmessages' , {
          query
        } )
        .then(response => {
      
          console.log( "messages " + response.data.length ) ;

          var items = response.data.map( (item) => {
            return( <RightNewaItem  key={item.id} message={item.message.substring(0,25) + "..."}></RightNewaItem> ) 
          })


          setListItems( items ) ; 
      
        })
        .catch(e => {
          console.log( "messages") ;
          console.log( e ) ;
        })
        .finally(  () => {
      
      
      })
      

  } 
  
  useEffect( () => {
      searchMessages() ;
  } , [])

  return (

    <div>
      <div className='d-flex gap-2'>
        <h2 className='h2 font-bold'>News</h2>
        <div className='circle-not'>10</div>
      </div>

      <div className='ex2'>
        {listItems}
      </div>
    </div>


  )
}
